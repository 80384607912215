import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import "../SignIn/SignIn.scss";

const postToApi1 = async (data) => {
  try {
    const response = await axios.post(
      "https://backend.ir4u.info/api/v1/signup",
      data
    );
    console.log("API 1 Response:", response.data);
    return response;
  } catch (error) {
    console.error("API 1 Error:", error);
    if (error.response) {
      if (
        error.response.data.error.includes(
          "write exception: write errors: [E11000 duplicate key error collection"
        )
      ) {
        Swal.fire(
          "Success",
          "Sent mail to provided mail id Please verify",
          "success"
        );
      } else {
        Swal.fire("", `${error.response.data.error}`, "error");
      }
      console.log("API 1 Error Response:", error.response.data);
    }
    throw error;
  }
};

const Register = () => {
  const navigate = useNavigate();
  const [signUpUserData, setSignUpUserData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    password: "",
    usertype: "app",
    role: "user",
    status: "active",
  });

  const [passwordVerification, setPasswordVerification] = useState({
    isLengthValid: true,
    hasSpecialCharacter: true,
    hasNumber: true,
    hasUppercase: true,
    hasLowercase: true,
  });
  const [trueConditionCount, setTrueConditionCount] = useState(0);

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    if (password === "") {
      setIssubmit(false);
      setPasswordVerification({
        isLengthValid: true,
        hasSpecialCharacter: true,
        hasNumber: true,
        hasUppercase: true,
        hasLowercase: true,
      });
      setTrueConditionCount(0);
      return;
    }

    const lengthRegex = /.{6,}/;
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;

    // Update password verification status
    const updatedVerification = {
      isLengthValid: lengthRegex.test(password),
      hasSpecialCharacter: specialCharacterRegex.test(password),
      hasNumber: numberRegex.test(password),
      hasUppercase: uppercaseRegex.test(password),
      hasLowercase: lowercaseRegex.test(password),
    };
    setPasswordVerification(updatedVerification);
    const trueConditionsCount =
      Object.values(updatedVerification).filter(Boolean).length;
    setTrueConditionCount(trueConditionsCount);
    console.log("contis ", trueConditionCount);
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem("jwtTokenApi1");
    if (jwtToken) {
      navigate("/");
    }
  }, [navigate]);

  const signupInputChange = (e) => {
    if (e.target.name === "password") {
      handlePasswordChange(e);
    }
    setSignUpUserData((prevUserData) => {
      // const updatedUserData = {
      //   ...prevUserData,
      //   [e.target.name]: e.target.value,
      // };
      const updatedUserData = {
        ...prevUserData,
        [e.target.name]:
          e.target.name === "username"
            ? e.target.value.toLowerCase()
            : e.target.value,
      };
      const areAllFieldsFilled = Object.values(updatedUserData).every(
        (value) => value !== ""
      );
      console.log(areAllFieldsFilled);
      return updatedUserData;
    });
  };
  const [isSubmit, setIssubmit] = useState(false);
  const handleSubmit = async () => {
    console.log(signUpUserData);
    const data = JSON.stringify(signUpUserData);
    if (
      !passwordVerification.isLengthValid ||
      !passwordVerification.hasSpecialCharacter ||
      !passwordVerification.hasNumber ||
      !passwordVerification.hasUppercase
    ) {
      setIssubmit(true);
      return;
    }
    try {
      //   const responses = await Promise.all([postToApi1(data), postToApi2(data)]);
      //   const responseApi1 = responses[0];
      //   const responseApi2 = responses[1];
      //   if (responseApi1.status === 201 && responseApi2.status === 200) {
      //     console.log(responseApi1, "responseApi1", "responseApi2", responseApi2);
      //     sendVerifyEmailLink(signUpUserData.username);

      const responseApi1 = await postToApi1(data);
      if (responseApi1.status === 201) {
        console.log("API 1 Response:", responseApi1);
        sendVerifyEmailLink(signUpUserData.username);
      } else {
        Swal.fire("Error", "Error in creating user", "error");
      }
    } catch (error) {
      console.error("Signup error:", error);
      console.log("error is ", error);
      console.log(error.response.data.message);
    }
  };
  console.log(signUpUserData.username);
  const sendVerifyEmailLink = async (username) => {
    try {
      const response = await axios.get(
        `https://backend.ir4u.info/api/v1/send/verification?username=${username}`,
        {
          headers: {
            apphost: window.location.origin,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        Swal.fire(
          "Success",
          "Sent mail to provided mail id Please verify",
          "success"
        );
        navigate("/signin");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="ir-ws-sign-in-main-container">
      <div className="ir-ws-sign-inner-container">
        <div className="ir-ws-sign-in-content-container">
          <div className="ir-ws-signin-logo-container">
            <div className="ir-ws-text-center ir-ws-signin-logo-inner-container flex items-center justify-center">
              <a href="/">
                <img
                  className="ir-ws-signin-logo-img"
                  src={require("../../assets/images/ir4u2.png")}
                  alt="logo"
                />
              </a>
            </div>
          </div>
          <div className="ir-ws-signup-content-inner-container">
            <h3 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-signup-content-title">
              Get Started
            </h3>
          </div>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await handleSubmit();
            }}
          >
            <div className="ir-ws-signup-form-container">
              <div className="ir-ws-signup-content-group">
                <input
                  className="ir-ws-signup-input-field"
                  name="firstname"
                  type="text"
                  onChange={signupInputChange}
                  value={signUpUserData.firstname}
                  required
                />
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
                <label className="ir-ws-signup-label">Firstname</label>
              </div>
              <div className="ir-ws-signup-content-group">
                <input
                  className="ir-ws-signup-input-field"
                  name="lastname"
                  type="text"
                  onChange={signupInputChange}
                  value={signUpUserData.lastname}
                  required
                />
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
                <label className="ir-ws-signup-label">Lastname</label>
              </div>
              <div className="ir-ws-signup-content-group">
                <input
                  className="ir-ws-signup-input-field"
                  name="username"
                  type="text"
                  onChange={signupInputChange}
                  value={signUpUserData.username}
                  required
                />
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
                <label className="ir-ws-signup-label">Username</label>
              </div>
              <div className="ir-ws-signup-content-group">
                <input
                  className="ir-ws-signup-input-field"
                  name="email"
                  type="email"
                  onChange={signupInputChange}
                  value={signUpUserData.email}
                  required
                />
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
                <label className="ir-ws-signup-label">Email</label>
              </div>
              <div className="ir-ws-signup-content-group ir-ws-password-input">
                <input
                  className="ir-ws-signup-input-field"
                  name="password"
                  type="password"
                  onChange={signupInputChange}
                  onBlur={handlePasswordChange}
                  value={signUpUserData.password}
                  required
                />
                <span className="ir-ws-signup-highlight"></span>
                <span className="ir-ws-signup-bar"></span>
                <label className="ir-ws-signup-label">Password</label>
              </div>
              <div className="password-verification">
                <div>
                  {!isSubmit && trueConditionCount == 0 && (
                    <div>
                      <div className="ir-ws-password-velbox">
                        <div style={{ backgroundColor: "#D6D6D6" }}></div>
                        <div style={{ backgroundColor: "#D6D6D6" }}></div>
                        <div style={{ backgroundColor: "#D6D6D6" }}></div>
                        <div style={{ backgroundColor: "#D6D6D6" }}></div>
                        <div style={{ backgroundColor: "#D6D6D6" }}></div>
                      </div>
                    </div>
                  )}
                  {!isSubmit && trueConditionCount > 0 && (
                    <div>
                      <div className="ir-ws-password-velbox">
                        {[...Array(5)].map((_, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor:
                                index < trueConditionCount
                                  ? "#00ff0a"
                                  : "#D6D6D6",
                            }}
                          ></div>
                        ))}
                      </div>
                    </div>
                  )}
                  {isSubmit && (
                    <div>
                      <div className="ir-ws-password-velbox">
                        {[...Array(5)].map((_, index) => (
                          <div
                            key={index}
                            style={{
                              backgroundColor:
                                index < trueConditionCount
                                  ? "#00ff0a"
                                  : "#ff0000",
                            }}
                          ></div>
                        ))}
                      </div>
                      <div className="ir-ws-password-indicator">
                        <span>Password should have:</span>{" "}
                        {[
                          !passwordVerification.isLengthValid && "6 characters",
                          !passwordVerification.hasSpecialCharacter &&
                            "a special character",
                          !passwordVerification.hasNumber && "a number",
                          !passwordVerification.hasUppercase &&
                            "an uppercase letter",
                          !passwordVerification.hasLowercase &&
                            "contains an lowercase letter",
                        ]
                          .filter(Boolean)
                          .join(", ")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className={`ir-ws-text-center ir-ws-default-btn-container  ir-ws-register-button-container'} `}
              >
                <button
                  className={`ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button ir-ws-register-button '}`}
                  style={{ width: "200px" }}
                >
                  <span>Sign Up</span>
                </button>
              </div>
            </div>
          </form>
          <div className="ir-ws-signup-form-footer-container">
            <div className="ir-ws-sign-footer-lin-container">
              <p className="ir-default-color">
                Already having an account? &nbsp;
                <b>
                  <a href="/signin" className="ir-ws-app-color ">
                    Signin
                  </a>
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

{
  /* <div className="ir-ws-login-social-container">
							<div className="ir-ws-flex ir-ws-justify-center ir-ws-align-center ir-login-social-content-container">
								<div className="ir-social-logo-container">
									<img src={require('../../assets/images/google-sign.png')} alt="google login" />
								</div>
								<div className="ir-social-logo-container">
									<img src={require('../../assets/images/fb-sign.png')} alt="facebook login" />
								</div>
								<div className="ir-social-logo-container">
									<img src={require('../../assets/images/twitter-sign.png')} alt="twitter login" />
								</div>
							</div>
						</div> */
}
