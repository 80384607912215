import React, { useState } from "react";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";
import ConfirmationModal from "../AdminCreateCourseTab/ConfirmationModal";

const CourseAdminContent = ({ toggle, setToggle }) => {
  // const handleCreateToggle = () => {
  //   setToggle(false);
  // };

  // const handleShowWebinarListToggle = () => {
  //   setToggle(true);
  // };
  const {
    activeTabCourse,
    setActiveTabCourse,
    selectedCourseIds,
    handleDeleteMultipleCourses,
  } = useModalContext();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const toggleConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };
  const handleCreateCourseTab = () => {
    setActiveTabCourse(1);
  };
  const handleCourseListTab = () => {
    setActiveTabCourse(2);
  };
  const handleTrashTab = () => {
    setActiveTabCourse(3);
  };
  const handleDraftTab = () => {
    setActiveTabCourse(4);
  };
  return (
    <>
      <div className="ir-ws-webinar-admin-custom-tab">
        <p
          onClick={handleCreateCourseTab}
          className={`ir-ws-custom-tab-button w-[175px] text-center ${
            activeTabCourse === 1 ? "" : "ir-ws-custom-tab-active"
          }`}
        >
          Courses
        </p>
        <p
          className={`ir-ws-custom-tab-button w-[175px] text-center ${
            activeTabCourse === 2 ? "" : "ir-ws-custom-tab-active"
          }`}
          onClick={handleCourseListTab}
        >
          Course List
        </p>
        <p
          className={`ir-ws-custom-tab-button w-[175px] text-center ${
            activeTabCourse === 3 ? "" : "ir-ws-custom-tab-active"
          }`}
          onClick={handleTrashTab}
        >
          Trash
        </p>
        <p
          className={`ir-ws-custom-tab-button w-[175px] text-center ${
            activeTabCourse === 4 ? "" : "ir-ws-custom-tab-active"
          }`}
          onClick={handleDraftTab}
        >
          Draft
        </p>
      </div>
      {selectedCourseIds.length !== 0 && (
        <div className="ir-ws-create-course-delete-all-container">
          <p onClick={toggleConfirmationModal}>Delete All</p>
        </div>
      )}
      {openConfirmationModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmationModal}
          confirmationFunc={async () => {
            await handleDeleteMultipleCourses();
            toggleConfirmationModal();
          }}
          title="delete selected courses"
        />
      )}
    </>
  );
};

export default CourseAdminContent;
