import React, { useState } from "react";
import WebinarAdminCustomTab from "../../WebinarAdminCustomTab/WebinarAdminCustomTab";
import WebinarAdminCreateWebinarTab from "../../WebinarAdminCreateWebinarTab/WebinarAdminCreateWebinarTab";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";
// import { useModalContext } from "../";
const WebinarPanel = () => {
  // const [toggle, setToggle] = useState(false);
  const { createWebinar, setCreateWebinar } = useModalContext();
  return (
    <div className="h-[100%]">
      <WebinarAdminCustomTab
        toggle={createWebinar}
        setToggle={setCreateWebinar}
      />
      <WebinarAdminCreateWebinarTab
        toggle={createWebinar}
        setToggle={setCreateWebinar}
      />
    </div>
  );
};

export default WebinarPanel;
