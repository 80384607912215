import React from "react";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";

const ChatAdminRoomContent = () => {
  const { activeTab, setActiveTab } = useModalContext();
  const handleActiveTab = () => {
    setActiveTab(1);
  };
  const handleInactiveTab = () => {
    setActiveTab(2);
  };
  const handleArchiveTab = () => {
    setActiveTab(3);
  };
  return (
    <div className="ir-ws-webinar-admin-custom-tab">
      <p
        onClick={handleActiveTab}
        className={`ir-ws-custom-tab-button w-[220px] text-center  ${
          activeTab === 1 ? "" : "ir-ws-custom-tab-active"
        }`}
      >
        Active Rooms
      </p>
      <p
        className={`ir-ws-custom-tab-button w-[220px] text-center ${
          activeTab === 2 ? "" : "ir-ws-custom-tab-active"
        }`}
        onClick={handleInactiveTab}
      >
        Inactive Rooms
      </p>
      <p
        className={`ir-ws-custom-tab-button w-[220px] text-center ${
          activeTab === 3 ? "" : "ir-ws-custom-tab-active"
        }`}
        onClick={handleArchiveTab}
      >
        Archived Rooms
      </p>
    </div>
  );
};

export default ChatAdminRoomContent;
