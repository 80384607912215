import React from "react";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";

const AdminProductComponent = ({ type }) => {
  const {
    handleSelectPanel,
    handleWebinarCreateModalOpen,
    handleCreateCase,
    handleCreateCourse,
  } = useModalContext();
  const openWebinarSection = () => {
    handleSelectPanel("Webinar");
    handleWebinarCreateModalOpen();
  };
  const openCaseSection = () => {
    handleSelectPanel("Cases");
    handleCreateCase();
  };
  const openCourseSection = () => {
    handleSelectPanel("Courses");
    handleCreateCourse();
  };
  return (
    <div className="w-[34%] ph-box-shadow h-[100%]">
      <div className="bg-[#FFFFFF]  rounded-[8px] h-[100%] text-[#1C1D26]">
        <div className="xl:p-[12px] 3xl:p-[20px] flex flex-col  h-[100%]">
          <div className="flex items-center ">
            <div>
              <p className="xl:text-[14px] text-[#0A0815] font-[700] 3xl:text-[16px]">
                {type}
              </p>
            </div>
            {/* <div className="text-[#646464] text-[24px] cursor-pointer">
                  <i className="bi bi-three-dots"></i>
                </div> */}
          </div>
          {type === "Create" && (
            <div className="flex flex-col justify-between items-center text-[#ffff] h-[100%] xl:pt-[12px] 3xl:pt-[20px] xl:text-[14px] 3xl:text-[16px] font-[500] ">
              <div
                className="w-[100%]  bg-[#8FE5FA]  rounded-[8px] h-[30%] flex items-center justify-center cursor-pointer"
                onClick={openCourseSection}
              >
                Course
              </div>
              <div
                className="w-[100%]  bg-[#D3D242]  rounded-[8px] h-[30%] flex items-center justify-center cursor-pointer"
                onClick={openCaseSection}
              >
                Cases
              </div>
              <div
                className="w-[100%]  bg-[#A089FD]  rounded-[8px] h-[30%] flex items-center justify-center cursor-pointer"
                onClick={openWebinarSection}
              >
                Webinar
              </div>
            </div>
          )}

          {type === "Sales" && (
            <div className=" h-[100%] xl:pt-[12px] 3xl:pt-[20px] flex flex-col xl:gap-[12px] 3xl:gap-[20px] xl:text-[14px] 3xl:text-[16px] text-[#fff]">
              <div className="flex w-[100%] h-[37%] xl:gap-[12px] 3xl:gap-[20px]">
                <div className="bg-[#FFBD98] flex flex-col justify-between  xl:p-[12px] 3xl:p-[20px] rounded-[8px] w-[100%] relative">
                  <p className="font-[500]">Chat</p>
                  <p className="font-[700] xl:text-[10px] 3xl:text-[12px]">
                    Total sale $200
                  </p>
                  <img
                    className="w-[25%] absolute top-[20px] right-[10px]"
                    src={require("../../../assets/images/circle-2.png")}
                    alt="circle"
                  />
                </div>
                <div className="bg-[#D3D242] flex flex-col justify-between  xl:p-[12px] 3xl:p-[20px] rounded-[8px] w-[100%] relative">
                  <p className="font-[500]">Cases</p>
                  <p className="font-[700] xl:text-[10px] 3xl:text-[12px]">
                    Total sale $200
                  </p>
                  <img
                    className="w-[25%] absolute top-[20px] right-[10px]"
                    src={require("../../../assets/images/circle-2.png")}
                    alt="circle"
                  />
                </div>
              </div>
              <div className="flex w-[100%] h-[37%] xl:gap-[12px] 3xl:gap-[20px] ">
                <div className="bg-[#8FE5FA] flex flex-col justify-between  xl:p-[12px] 3xl:p-[20px] rounded-[8px] w-[100%] relative">
                  <p className="font-[500]">Courses</p>
                  <p className="font-[700] xl:text-[10px] 3xl:text-[12px]">
                    Total sale $200
                  </p>
                  <img
                    className="w-[30%] absolute bottom-[-10px] right-[0px]"
                    src={require("../../../assets/images/rectangle-1.png")}
                    alt="circle"
                  />
                </div>
                <div className="bg-[#A089FD] flex flex-col justify-between  xl:p-[12px] 3xl:p-[20px] rounded-[8px] w-[100%] relative">
                  <p className="font-[500]">Webinar</p>
                  <p className="font-[700] xl:text-[10px] 3xl:text-[12px]">
                    Total sale $200
                  </p>
                  <img
                    className="w-[30%] absolute bottom-[-10px] right-[0px]"
                    src={require("../../../assets/images/rectangle-2.png")}
                    alt="circle"
                  />
                </div>
              </div>
              <div className="ir-ad-pn-border w-[100%] flex flex-col justify-between  xl:p-[6px] 3xl:p-[14px]  h-[26%] rounded-[8px] text-[#0C0B18]">
                <p className="xl:text-[14px] 3xl:text-[16px]">
                  Total Sale : $1500
                </p>
                <p className="xl:text-[10px] 3xl:text-[12px]">Month of Jan</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminProductComponent;
