import React from "react";
import WebinarAdminUserSection from "../../WebinarAdminUserSection/WebinarAdminUserSection";

const UsersPanel = () => {
  return (
    <div className="h-[100%]">
      <WebinarAdminUserSection />
    </div>
  );
};

export default UsersPanel;
