const BaseURL = "https://staging-chats.ir4u.info/api/v1";

export const saveFcmToken = async (token) => {
  try {
    const jwtToken = localStorage.getItem("jwtTokenApi1");

    const response = await fetch(`${BaseURL}/add/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ apiKey: token }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    console.log("FCM Token saved successfully:", data);
    return data;
  } catch (error) {
    console.error("Error saving FCM Token:", error.message);
    throw error;
  }
};
