import React, { useState } from "react";
import ConfirmationModal from "../Courses/AdminCreateCourseTab/ConfirmationModal";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import { useDispatch } from "react-redux";
import { setCaseData } from "./CaseSlice/Caseslice";
import axios from "axios";
import Swal from "sweetalert2";

const AdminCaseList = () => {
  const {
    cases,
    deleteCaseFunction,
    setActiveTabCase,
    handleCreateCase,
    isUpdateCase,
    caseUpdateId,
    allCaseTransaction,
  } = useModalContext();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedCaseIds, setSelectedCaseIds] = useState([]);
  const [caseId, setCaseId] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);

  const dispatch = useDispatch();

  const [contentVisibleCaseId, setContentVisibleCaseId] = useState(null);

  const handleCheckboxChange = (id) => {
    setSelectedCaseIds((prevSelectedCaseIds) =>
      prevSelectedCaseIds.includes(id)
        ? prevSelectedCaseIds.filter((caseId) => caseId !== id)
        : [...prevSelectedCaseIds, id]
    );
  };
  const toggleConfirmationModal = (id) => {
    // console.log("case id: " + id);
    setCaseId(id);
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const handleDeleteConfirmation = async () => {
    console.log("clicked delete confirmation of caseId:", caseId);
    if (caseId) {
      await deleteCaseFunction(caseId);
      setCaseId("");
      setOpenConfirmationModal(false);
    }
  };

  const handleUpdateCase = async (caseId) => {
    try {
      const response = await axios.get(
        `https://case.ir4u.info/api/v1/admin/case/${caseId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
          },
        }
      );

      const caseData = response.data.data;

      if (!caseData.slides || caseData.slides.length === 0) {
        Swal.fire("", "No slides available for this case.", "error");
        return;
      }

      if (!caseData.casePrice) {
        Swal.fire("", "No case price available.", "error");
        return;
      }

      dispatch(setCaseData(caseData));
      setActiveTabCase(1);
      handleCreateCase(caseId);
      isUpdateCase(caseId);
      // console.log("caseUpdateId:",caseUpdateId);
    } catch (error) {
      console.error("Error fetching case data:", error);
      Swal.fire(
        "",
        `Error fetching case data: ${error?.message || error}`,
        "error"
      );
    }
  };

  const accordianListButton = [
    "Username",
    "Card",
    "Status of Transaction",
    "Tax Invoice Number",
    "Invoice",
  ];

  const toggleTransactionDropdown = (caseId) => {
    if (contentVisibleCaseId === caseId) {
      setContentVisibleCaseId(null);
      setFilteredTransactions([]);
    } else {
      const filteredTransactions = allCaseTransaction?.filter(
        (transaction) => transaction.productId === caseId
      );
      const successTransactions = filteredTransactions?.filter(
        (transaction) => transaction.status === "SUCCESS"
      );
      setFilteredTransactions(successTransactions);
      setContentVisibleCaseId(caseId);
    }
  };
  console.log("Admin Case List", cases);
  return (
    <>
      <div>
        {cases?.map((caseItem, index) => (
          <div className="ir-ws-webinar-list-tab" key={index}>
            <div className="ir-ws-admin-webinar-content">
              <div className="ir-ws-admin-webinar-title-container">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id={`checkbox1${caseItem._id}`}
                    className="checkbox__input"
                    onChange={() => handleCheckboxChange(caseItem.id)}
                    checked={selectedCaseIds.includes(caseItem.id)}
                  />
                  <label
                    htmlFor={`checkbox1${caseItem.id}`}
                    className="checkbox__label"
                  ></label>
                </div>
                {/* <img
                      src={course.formData.imagePreview}
                      className="ir-ws-course-list-image"
                      alt="course"
                    /> */}
                <p className="ir-ws-webinar-title-text">
                  {caseItem.slides[0]?.name || "Loading..."}
                </p>
              </div>
              <div className="ir-ws-admin-list-button-container">
                <button
                  onClick={() => handleUpdateCase(caseItem._id)}
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                >
                  Update
                </button>
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                  onClick={() => toggleConfirmationModal(caseItem._id)}
                >
                  Delete
                </button>
                <button
                  className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button flex items-center justify-center gap-[4px]"
                  onClick={() => toggleTransactionDropdown(caseItem._id)}
                >
                  Transactions
                  <i class="bi bi-chevron-down"></i>
                </button>
              </div>
            </div>
            {contentVisibleCaseId === caseItem._id && (
              <div>
                {filteredTransactions.length !== 0 && (
                  <ul className="ir-ws-webinar-list-accordian-tab">
                    {accordianListButton.map((item, index) => (
                      <li className="ir-ws-list-item-box" key={index}>
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
                <div>
                  {filteredTransactions.length === 0 ? (
                    <ul className="ir-ws-webinar-list-data">
                      <li>No purchase history</li>
                    </ul>
                  ) : (
                    filteredTransactions?.map((item, index) => (
                      <ul className="ir-ws-webinar-list-data" key={index}>
                        <li>{item?.username}</li>
                        <li>{item?.productPaymentDetails?.paymentMode}</li>
                        <li>{item?.status}</li>
                        <li>{item?.transactionId}</li>
                        <li className="flex items-center justify-center">
                          <a
                            href={item?.invoiceURL}
                            className="ir-ws-webinar-inovice-icon"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="ir-ws-webinar-download-icon"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
        {openConfirmationModal && (
          <ConfirmationModal
            toggleModal={toggleConfirmationModal}
            confirmationFunc={handleDeleteConfirmation}
            title="delete"
          />
        )}
      </div>
    </>
  );
};

export default AdminCaseList;
