import React, { useContext, useEffect, useState } from "react";
import { RootStore } from "../../store/store";
import { usePaymentContext } from "../../context/PaymentContext";
import { ConfirmationTypes } from "../../constants";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";
import ActiveRoomsTab from "../../components/Chat/ChatAdmin/ChatRoomAdminTab/ActiveRoomsTab";
import InactiveRoomsTab from "../../components/Chat/ChatAdmin/ChatRoomAdminTab/InactiveRoomTab";
import ArchiveAdminRoomTab from "./ArchiveAdminRoomTab";
import AdminConfirmModal from "../../components/Chat/AdminConfirmModal/AdminConfirmModal";
import ArchiveAdminDataModal from "../../components/Chat/ArchiveAdminDataModal/ArchiveAdminDataModal";

const ChatAdminRoomDashboard = (props) => {
  const { activeTab, setActiveTab } = useModalContext();
  const [store, setStore] = useContext(RootStore);
  const { chatStore, userStore, socketConnection } = store;
  const [archive, setArchive] = useState(false);

  //   const [selectedFile, setSelectedFile] = useState("");
  const [profileData, setProfileData] = useState(null);
  //   const [enableInputField, setEnableInputField] = useState({});
  //   const [updateProfileInputData, setUpdateProfileInputData] = useState({});
  //   const [updateProfilePicModal, setUpdateProfilePicModal] = useState(false);
  //   const [updateProfilePic, setUpdateProfilePic] = useState(false);
  const [currentChatInfo, setCurrentChatInfo] = useState(null);
  const [currentUserInfo, setCurrentUserInfo] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  //currentChatId, confirmationType
  //create on fn
  // showconfirmation true
  //handleConfirmSubmit

  const [showAdminDataModal, setShowAdminDataModal] = useState(false);
  // const [activeRoomsData, setActiveRoomsData] = useState([]);

  // console.log(activeRoomsData);

  const { paymentResponses, setPaymentResponses } = usePaymentContext();

  useEffect(() => {
    console.log("Payment responses updated:", paymentResponses);
  }, [paymentResponses]);

  const { connection, ioState } = socketConnection;

  const handleAction = (_chatInfo, _actionType) => {
    setCurrentChatInfo(_chatInfo);
    setActionType(_actionType);
    setShowConfirmation(true);
  };

  const handleUserAction = (_userInfo, _actionType) => {
    setCurrentUserInfo(_userInfo);
    setActionType(_actionType);
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    if (actionType === ConfirmationTypes.FREEZE) {
      await freezeRoom(currentChatInfo.id);
    } else if (actionType === ConfirmationTypes.UNFREEZE) {
      await unFreezeRoom(currentChatInfo.id);
    } else if (actionType === ConfirmationTypes.ARCHIVE) {
      // await addToArchieve(currentChatInfo.id);
      setShowAdminDataModal(true);
    } else if (actionType === ConfirmationTypes.UNARCHIVE) {
      await removeFromArchive(currentChatInfo.id);
    } else if (actionType === ConfirmationTypes.DELETEROOM) {
      await deleteRoom(currentChatInfo.id);
    } else if (actionType === ConfirmationTypes.DELETECONTENT) {
      await deleteRoomConent(currentChatInfo.id);
    } else if (actionType === ConfirmationTypes.ACTIVEUSER) {
      await activeUser(currentUserInfo.id);
    } else if (actionType === ConfirmationTypes.INACTIVEUSER) {
      await inactiveUser(currentUserInfo.id);
    } else if (actionType === ConfirmationTypes.DELETEUSER) {
      await deleteUser(currentUserInfo.id);
    }

    setShowConfirmation(false);
  };

  const handleCancel = () => {
    setCurrentChatInfo(null);
    setActionType(null);
    setShowConfirmation(false);
  };

  const addToArchieve = async (id, price) => {
    if (!price) return;
    try {
      const response = await axios({
        url: `chat-group/${id}/archive`,
        method: "put",
        params: {
          archive: true,
          price: price,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
        },
      });

      if (response) {
        setShowAdminDataModal(false);
        Swal.fire({
          title: "Success!",
          text: "Room has been successfully archived.",
          icon: "success",
        });

        props.getChatRooms(); // Refresh chat rooms

        // let chatgroups = response.data.data;

        // setActiveRoomsData(response);

        // let chatStore = { ...store.chatStore };
        // let responseData = { ...response.data.data };

        // if (responseData.image !== "" && responseData.image.indexOf(", ")) {
        //   responseData.image = responseData.image.split(", ");
        // }

        // let groups = [...chatStore.groups];
        // let index = null;
        // for (let i = 0; i < groups.length; i++) {
        //   let id = groups[i].id;
        //   if (id === responseData.id) {
        //     index = i;
        //     break;
        //   }
        // }
        // // let index = responseData?.id &&  groups.findIndex(responseData?.id) || null;

        // if (index != null) {
        //   groups[index] = { ...responseData };
        // }

        console.log("resposnse data is ", response);
        // chatStore.groups.push(responseData);

        setArchive(true);
        // setStore({
        //   ...store,
        //   chatStore: { ...chatStore, groups: [...groups] },
        // });
      }
    } catch (error) {
      console.error("Error archiving room:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to archive the room.",
        icon: "error",
      });
    }
  };

  const removeFromArchive = async (id) => {
    try {
      const response = await axios({
        // url: `chat-group/${id}/archive`,
        url: `chat-group/${id}/unarchive`, // Updated URL
        method: "put",
        // params: { archive: false },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
        },
      });

      if (response) {
        // setShowAdminDataModal(false);
        Swal.fire({
          title: "Success!",
          text: "Room is successfully unarchived.",
          icon: "success",
        });

        props.getChatRooms();

        console.log("resposnse data is ", response);
        // chatStore.groups.push(responseData);

        setArchive(false);
      }
    } catch (error) {
      console.error("Error unarchiving room:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to unarchive the room.",
        icon: "error",
      });
    }
  };

  const freezeRoom = async (id) => {
    console.log("Freeze room", id);
    const response = await axios({
      url: `chat-group/${id}/freeze`,
      method: "put",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    console.log("freeze response is", response);
    if (response) {
      Swal.fire({
        title: "Success!",
        text: "Room is successfully freezed",
        icon: "success",
      });
      props.getChatRooms();
      // let chatgroups = response.data.data;
      //   let chatStore = { ...store.chatStore };
      //   let responseData = { ...response.data.data };
      //   if (responseData.image !== "" && responseData.image.indexOf(", ")) {
      //     responseData.image = responseData.image.split(", ");
      //   }

      //   let groups = [...chatStore.groups];
      //   let index = null;
      //   for (let i = 0; i < groups.length; i++) {
      //     let id = groups[i].id;
      //     if (id === responseData.id) {
      //       index = i;
      //       break;
      //     }
      //   }
      //   // let index = responseData?.id &&  groups.findIndex(responseData?.id) || null;

      //   if (index != null) {
      //     groups[index] = { ...responseData };
      //   }

      //   console.log("resposnse data is ", responseData);
      //   // chatStore.groups.push(responseData);

      //   setStore({
      //     ...store,
      //     chatStore: { ...chatStore, groups: [...groups] },
      //   });
    }
  };

  const unFreezeRoom = async (id) => {
    const response = await axios({
      url: `chat-group/${id}/freeze`,
      method: "put",
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });

    if (response) {
      Swal.fire({
        title: "Success!",
        text: "Room is successfully unfreezed",
        icon: "success",
      });

      props.getChatRooms();
      // let chatgroups = response.data.data;
      //   let chatStore = { ...store.chatStore };
      //   let responseData = { ...response.data.data };
      //   if (responseData.image !== "" && responseData.image.indexOf(", ")) {
      //     responseData.image = responseData.image.split(", ");
      //   }

      //   let groups = [...chatStore.groups];
      //   let index = null;
      //   for (let i = 0; i < groups.length; i++) {
      //     let id = groups[i].id;
      //     if (id === responseData.id) {
      //       index = i;
      //       break;
      //     }
      //   }
      //   // let index = responseData?.id &&  groups.findIndex(responseData?.id) || null;

      //   if (index != null) {
      //     groups[index] = { ...responseData };
      //   }

      //   console.log("resposnse data is ", responseData);
      //   // chatStore.groups.push(responseData);

      //   setStore({
      //     ...store,
      //     chatStore: { ...chatStore, groups: [...groups] },
      //   });
    }
  };

  const deleteRoom = async (id, currentRoom, totalRooms) => {
    const response = await axios({
      url: "group/delete/" + id,
      method: "delete",
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    if (response.status === 200) {
      Swal.fire({
        title: "Success!",
        text: "Room is successfully deleted",
        icon: "success",
      });
      let groups = [...chatStore.groups];
      let index = groups.findIndex((e) => e.id === id);
      groups.splice(index, 1);
      // chatStore.groups.push(responseData);
      setStore({
        ...store,
        chatStore: {
          ...chatStore,
          groups: [...groups],
        },
      });
      let selectedRom = currentRoom;
      if (currentRoom == totalRooms - 1) {
        selectedRom = currentRoom - 1;
      } else if (currentRoom == 0) {
        selectedRom = currentRoom + 1;
      } else {
        selectedRom = currentRoom + 1;
      }
    }
  };

  const deleteRoomConent = async (id) => {
    const response = await axios({
      url: "chat/history/" + id,
      method: "delete",
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    if (response.status === 200) {
      Swal.fire({
        title: "Success!",
        text: "Room content is successfully deleted",
        icon: "success",
      });
    }
  };

  const activeUser = async (id, status = "inactive") => {
    console.log("clicked active user", id, status);
    let userStore = [...store.userStore.users];

    let index = userStore.findIndex((e) => e.id === id);
    let fd = new FormData();
    fd.append("status", status);

    const response = await axios({
      url: "user/profile/" + id,
      method: "PUT",
      withCredentials: false,
      data: fd,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    console.log("userStore admin", response);
    if (response) {
      Swal.fire({
        title: "Success!",
        text: "User is successfully active",
        icon: "success",
      });
      userStore[index] = { ...response.data.data };
      setStore({
        ...store,
        userStore: {
          ...userStore,
          users: [...userStore],
        },
      });
    }
  };

  const inactiveUser = async (id, status = "active") => {
    console.log("clicked");
    let userStore = [...store.userStore.users];

    let index = userStore.findIndex((e) => e.id === id);
    let fd = new FormData();
    fd.append("status", status);

    const response = await axios({
      url: "user/profile/" + id,
      method: "PUT",
      withCredentials: false,
      data: fd,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    console.log("userStore admin", response);
    if (response) {
      Swal.fire({
        title: "Success!",
        text: "User is successfully ianctive",
        icon: "success",
      });
      userStore[index] = { ...response.data.data };
      setStore({
        ...store,
        userStore: {
          ...userStore,
          users: [...userStore],
        },
      });
    }
  };

  const deleteUser = async (id) => {
    console.log("clicked");
    let userStore = [...store.userStore.users];

    let index = userStore.findIndex((e) => e.id === id);

    const response = await axios({
      url: "user/profile/" + id,
      method: "DELETE",
      withCredentials: false,
      headers: {
        "Content-Type": "multiplart/form-data",
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
      },
    });
    console.log("userStore admin", response);
    if (response) {
      Swal.fire({
        title: "Success!",
        text: "User is successfully deleted",
        icon: "success",
      });
      userStore[index] = { ...response.data.data };
      setStore({
        ...store,
        userStore: {
          ...userStore,
          users: [...userStore],
        },
      });
    }
  };

  useEffect(() => {
    setProfileData(userStore.profile);
  }, [userStore.profile]);

  //   useEffect(() => {
  //     if (!connection) props.socket.connect();
  //     if (!connection && ioState) {
  //       props.socket.on("disconnect", (reason) => {
  //         props.socket.connect();
  //       });
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [connection]);
  return (
    <div>
      {activeTab === 1 && (
        <ActiveRoomsTab
          getChatRooms={props.getChatRooms}
          data={chatStore}
          onAction={handleAction}
        />
      )}
      {activeTab === 2 && (
        <InactiveRoomsTab
          getChatRooms={props.getChatRooms}
          data={chatStore}
          onAction={handleAction}
        />
      )}
      {activeTab === 3 && (
        <ArchiveAdminRoomTab
          data={chatStore}
          addToArchieve={addToArchieve}
          removeFromArchive={removeFromArchive}
          getChatRooms={props.getChatRooms}
        />
      )}
      {showConfirmation && (
        <AdminConfirmModal onConfirm={handleConfirm} onCancel={handleCancel} />
      )}

      {showAdminDataModal && (
        <ArchiveAdminDataModal
          closeModal={() => setShowAdminDataModal(false)}
          // activeRoomsData={activeRoomsData}
          addToArchieve={addToArchieve}
          activeRoomId={currentChatInfo.id}
        />
      )}
    </div>
  );
};

export default ChatAdminRoomDashboard;
