import React from "react";
import AdminSideNavigation from "./AdminSideNavigation";
import { AdminPanelBar } from "./AdminPanelBar";
import "./AdminDashboard.scss";

export const AdminDashboard = () => {
  return (
    <div className=" bg-[#F2F6FE] h-[100vh] p-[24px] flex xl:gap-[12px] 3xl:gap-[20px] xl:p-[12px] 3xl:p-[20px] overflow-hidden">
      <AdminSideNavigation />
      <AdminPanelBar />
    </div>
  );
};
