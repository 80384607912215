import React, { useContext, useState, useEffect } from "react";
import { RootStore } from "../../../store/store";
import "./ChatNavigation.scss";
import ChatNavImg from "../../../assets/images/chat-images/chat-nav.png";
import FavNavImg from "../../../assets/images/chat-images/fav-nav.png";
import ArchNavImg from "../../../assets/images/chat-images/arch-nav.png";
import NotNavImg from "../../../assets/images/chat-images/not-nav.png";
import SetNavImg from "../../../assets/images/chat-images/set-nav.png";
import SetNavImgActive from "../../../assets/images/chat-images/set-nav-active.png";
import ChatNavImgActive from "../../../assets/images/chat-images/chat-nav-active.png";
import FavNavImgActive from "../../../assets/images/chat-images/fav-nav-active.png";
import ArchNavImgActive from "../../../assets/images/chat-images/arch-nav-active.png";
import NotNavImgActive from "../../../assets/images/chat-images/not-nav-active.png";
import NoImage from "../../../assets/images/chat-images/no-image.png";

const ChatNavigation = ({
  setNavMenuItemActive,
  navMenuItemActive,
  initials,
}) => {
  // const [backgroundColor, setBackgroundColor] = useState(getRandomColor());

  const [store, setStore] = useContext(RootStore);
  const { userStore } = store;

  console.log("userStore", userStore);

  //   const profileImg = userStore?.profile?.image || "";

  const profileImg = userStore?.profile?.userImage || "";
  const profileInitials = `${
    userStore?.profile?.firstname
      ? userStore.profile.firstname[0].toUpperCase()
      : ""
  }${
    userStore?.profile?.lastname
      ? userStore.profile.lastname[0].toUpperCase()
      : ""
  }`;

  const ChatNavValues = [
    {
      id: 1,
      alttitle: "Chat",
      img: ChatNavImg,
      imgActive: ChatNavImgActive,
    },
    {
      id: 2,
      alttitle: "Favourites",
      img: FavNavImg,
      imgActive: FavNavImgActive,
    },
    {
      id: 3,
      alttitle: "Archive",
      img: ArchNavImg,
      imgActive: ArchNavImgActive,
    },
    {
      id: 4,
      alttitle: "Notifications",
      img: NotNavImg,
      imgActive: NotNavImgActive,
    },
    // {
    //     id: 5,
    //     alttitle: "Settings",
    //     img: SetNavImg,
    //     imgActive: SetNavImgActive
    // },
    {
      id: 6,
      alttitle: "Profile",
      img: profileImg,
      imgActive: profileImg,
    },
  ];

  // const [backgroundColor, setBackgroundColor] = useState(getSavedColor() || getRandomColor());
  const getRandomColor = () => {
    // const colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b'];
    // return colors[Math.floor(Math.random() * colors.length)];
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const saveColor = (color) => {
    localStorage.setItem(`userIconColor_${initials}`, color);
  };

  const getSavedColor = () => {
    return localStorage.getItem(`userIconColor_${initials}`);
  };

  const [backgroundColor, setBackgroundColor] = useState(
    getSavedColor() || getRandomColor()
  );
  const handleLogout = () => {
    //* Remove the token
    localStorage.removeItem("jwtTokenApi1");
    localStorage.removeItem("jwtTokenApi1");
    localStorage.removeItem("loggedInUsername");
    localStorage.removeItem("registeredUsername");
    // setIsLoggedIn(false);
    // setIsRegistered(false);
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("isRegistered", false);
  };

  useEffect(() => {
    const savedColor = getSavedColor();
    if (savedColor) {
      setBackgroundColor(savedColor);
    }
  }, []);

  return (
    <div className="ir-chat-navigation-container ir-ws-flex ir-ws-side-menu flex-lg-column">
      <div className="ir-ws-text-center flex ir-ws-navbar-brand-box">
        <a className="logo logo-light" href="/">
          <img
            src={require("../../../assets/images/chat-images/ir4u4.png")}
            alt="logo"
          />
          <p>IR4U.info</p>
        </a>
      </div>
      <ul role="tablist" className="side-menu-nav">
        {ChatNavValues.map((val, id) => {
          if (val.id !== 6) {
            return (
              <li
                key={id}
                className={`side-nav-item ${
                  navMenuItemActive === id ? "activeMenuItem" : ""
                }`}
                onClick={() => setNavMenuItemActive(id)}
                id={val.alttitle}
              >
                <a
                  href={void 0}
                  id="pills-chat-tab"
                  role="tab"
                  data-tip
                  data-for={val.alttitle}
                  className={`nav-link ${
                    navMenuItemActive === id ? "active" : ""
                  }`}
                >
                  {navMenuItemActive === id ? (
                    <img
                      src={val.imgActive}
                      alt={val.alttitle}
                      className={
                        val.imgActive === NotNavImgActive
                          ? "icon-animation"
                          : ""
                      }
                    />
                  ) : (
                    <img src={val.img} alt={val.alttitle} />
                  )}
                </a>
                <span className="ir-nav-tooltip">
                  {val.alttitle} {initials}
                </span>
              </li>
            );
          }
        })}
        {/* <li className="side-nav-item" id="Profile">
          <a
            href={void 0}
            id="pills-setting-tab"
            role="tab"
            className={`nav-link ${
              userStore?.profile.image === "" ? "user-img-replace-txt" : ""
            }`}
            onClick={() => setNavMenuItemActive(5)}
          >
            {userStore?.profile.image === "" ? (
              <p style={{ backgroundColor: backgroundColor }}>
                {userStore?.profile?.firstname[0] +
                  userStore?.profile?.lastname[0]}
              </p>
            ) : (
              <img
                src={userStore?.profile?.image}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = NoImage;
                }}
                className="profile-icon"
                alt="profile_pic"
              />
            )}
          </a>
          <span className="ir-nav-tooltip">Profile</span>
        </li> */}
        <li className="side-nav-item" id="Profile">
          <a
            href={void 0}
            id="pills-setting-tab"
            role="tab"
            className={`nav-link ${!profileImg ? "user-img-replace-txt" : ""}`}
            onClick={() => setNavMenuItemActive(5)}
          >
            {!profileImg ? (
              <p style={{ backgroundColor: backgroundColor }}>
                {profileInitials}
              </p>
            ) : (
              <img
                src={profileImg}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = NoImage;
                }}
                className="profile-icon"
                alt="profile_pic"
              />
            )}
          </a>
          <span className="ir-nav-tooltip">Profile</span>
        </li>

        <li className="side-nav-item">
          <a
            href="/"
            id="pills-setting-tab"
            role="tab"
            className="nav-link"
            onClick={handleLogout}
          >
            <img
              src={require("../../../assets/images/chat-images/log-nav.png")}
              alt="Logout"
            />
          </a>
          <span className="ir-nav-tooltip">Logout</span>
        </li>
      </ul>
    </div>
  );
};

export default ChatNavigation;
