import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";

const AdminSideNavigation = () => {
  const { adminPanelType, handleSelectPanel, handleLogout } = useModalContext();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const adminNavigationBar = [
    {
      id: 1,
      name: "Dashboard",
      icon: require("../../assets/images/dashboard-ina.png"),
      activeIcon: require("../../assets/images/dashboard.png"),
    },
    {
      id: 2,
      name: "Chat",
      icon: require("../../assets/images/chat-ina.png"),
      activeIcon: require("../../assets/images/chat-a.png"),
    },
    {
      id: 3,
      name: "Courses",
      icon: require("../../assets/images/course-ina.png"),
      activeIcon: require("../../assets/images/course-a.png"),
    },
    {
      id: 4,
      name: "Cases",
      icon: require("../../assets/images/case-ina.png"),
      activeIcon: require("../../assets/images/case-a.png"),
    },
    {
      id: 5,
      name: "Webinar",
      icon: require("../../assets/images/webinar-ina.png"),
      activeIcon: require("../../assets/images/webinar-a.png"),
    },
    {
      id: 6,
      name: "Users",
      icon: require("../../assets/images/user-ina.png"),
      activeIcon: require("../../assets/images/user-a.png"),
    },
    {
      id: 7,
      name: "Second Admin",
      icon: require("../../assets/images/admin-ina.png"),
      activeIcon: require("../../assets/images/admin-a.png"),
    },
  ];

  const navigate = useNavigate();
  return (
    <div className="bg-[#6842EF] w-[18%] max-w-[360px] h-[100%] rounded-[8px] relative">
      <div className="px-[25px] py-[30px] xl:py-[20px] xl:px-[30px] 3xl:py-[35px] 3xl:px-[35px] h-[100%] flex flex-col justify-between">
        <div>
          <div className="flex items-center justify-center cursor-pointer">
            <img
              className="w-[100%] xl:w-[70px] 3xl:w-[100px]"
              src={require("../../assets/images/ir4u-logo-1.png")}
              alt="logo"
              onClick={() => navigate("/")}
            />
          </div>
          <div className=" py-[30px] xl:py-[35px] 3xl:py-[50px]">
            <div>
              <div className="flex flex-col gap-[35px] xl:gap-[15px] 3xl:gap-[30px]">
                {adminNavigationBar.map((item) => (
                  <div
                    className={`flex items-center justify-between cursor-pointer hover:text-[#fff] hover:font-[600] ${
                      adminPanelType === item.name
                        ? "text-[#ffff] font-[600]"
                        : "text-[#D9D9D9]"
                    } ph-nav-bar-cont`}
                    key={item.id}
                    onClick={() => handleSelectPanel(item.name)}
                    onMouseEnter={() => setHoveredItem(item.name)}
                    onMouseLeave={() => setHoveredItem(null)}
                  >
                    <div className="flex items-center gap-[10px] 3xl:gap-[15px] xl:gap-[5px]">
                      {adminPanelType === item.name && (
                        <div className="w-[8px] bg-[#ffff] h-[40px] xl:h-[30px] 3xl:h-[40px] rounded-r-[8px] absolute left-[0px]"></div>
                      )}

                      <div className="w-7 h-7">
                        <img
                          className="h-full w-full object-contain xl:w-[20px] 3xl:w-[30px]"
                          src={
                            adminPanelType === item.name ||
                            hoveredItem === item.name
                              ? item.activeIcon
                              : item.icon
                          }
                          // src={item.icon}
                          alt={`${item.name} icon`}
                        />
                      </div>

                      <div className="xl:text-[12px] 3xl:text-[16px]">
                        {item.name}
                      </div>
                    </div>
                    {item.notification && (
                      <div className="w-[25px] h-[25px] flex items-center justify-center rounded-full bg-[#D9D9D9]">
                        <p className="text-[#394180] text-[12px]">1</p>
                      </div>
                    )}
                  </div>
                ))}
                {/* <div className="w-[100%] bg-[#D9D9D9] h-[1px]"></div>
                <div
                  className="flex items-center gap-[10px] cursor-pointer text-[#D9D9D9]  3xl:gap-[15px] xl:gap-[5px]"
                  onClick={() => handleSelectPanel("Settings")}
                  //   onMouseEnter={() => setHoveredItem("Settings")}
                  //   onMouseLeave={() => setHoveredItem(null)}
                >
                  <div className="w-7 h-7">
                    <img
                      className="h-full w-full object-contain xl:w-[20px] 3xl:w-[30px]"
                      //   src={
                      //     adminPanelType === "Settings" ||
                      //     hoveredItem === "Settings"
                      //       ? require("../../../assets/settings-active.png")
                      //       : require("../../../assets/setting.png")
                      //   }
                      src={require("../../assets/images/dashboard.png")}
                      alt="Settings icon"
                    />
                  </div>
                  <div className="xl:text-[12px] 3xl:text-[16px]">Settings</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="cursor-pointer relative">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="w-[45px] h-[45px] 3xl:w-[45px] 3xl:h-[45px] xl:w-[35px] xl:h-[35px] bg-[#D9D9D9] rounded-full"></div>
              <div className="text-[#D9D9D9] pl-[10px] ">
                <p className="font-[600] xl:text-[12px] 3xl:text-[16px]">
                  Admin
                </p>
                <p className="xl:text-[10px] 3xl:text-[14px]">
                  admin@gmail.com
                </p>
              </div>
            </div>
            <div onClick={() => setShowDropdown(!showDropdown)}>
              {/* <i className="bi bi-chevron-down text-[#D9D9D9] text-[24px]"></i>
               */}
              <i
                className={`bi bi-chevron-${
                  showDropdown ? "up" : "down"
                } text-[#D9D9D9] xl:text-[16px] 3xl:text-[20px]`}
              ></i>
            </div>
          </div>
          {showDropdown && (
            <div className="absolute right-[0px] bottom-[40px] mt-2  bg-[#F2F6FE] border rounded shadow-lg">
              <ul className="p-[8px]">
                <li
                  className=" text-[#1C1D26] cursor-pointer font-medium xl:text-[12px] 3xl:text-[16px]"
                  onClick={handleLogout}
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminSideNavigation;
