import React from "react";
import "./HomeChatFeatureContainer.scss";

const HomeChatFeatureContainer = () => {
  return (
    <div className="ir-ws-home-chat-feature-inside-gradient">
      <div className="ir-ws-padding-tb-100 ir-ws-padding-lr-132 ir-ws-hme-cht-main-container">
        <h2 className="ir-ws-text-center ir-ws-hme-prvw-title">
          Community Chat with Specialists in Real-Time
        </h2>
        <div className="ir-ws-align-center ir-ws-hme-cht-inner-container">
          <div className="ir-ws-hme-cht-img-container flex justify-center items-center">
            <img
              src={require("../../assets/images/home-chat-thumbnail.webp")}
              alt="home chat img container"
            />
          </div>
          <div className="ir-ws-hme-cht-content-container">
            <h3 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-hme-cht-title">
              Connect With Experts through our IR4U Chat
            </h3>
            <h4 className="ir-ws-hme-cht-scnd-title">
              Message experts and gain knowledge in real time
            </h4>
            <p className="ir-default-color ir-ws-hme-chat-para">
              Utilize our Chat feature to connect with experienced
              interventional radiologists, seek answers, ask questions, and gain
              a deeper understanding of IR concepts.
            </p>
            <div className="ir-ws-hme-chat-btn-container">
              <a
                href="/chat-room"
                target="blank"
                className="ir-ws-app-bg ir-ws-text-uppercase ir-color-white ir-ws-no-border ir-ws-hme-chat-btn"
              >
                <span>Start Chatting Now</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeChatFeatureContainer;
