import React from "react";
import { useModalContext } from "../../components/WebinarAdminCreateWebinarTab/ModalContext";

const CaseAdminContent = ({ toggle, setToggle }) => {
  // const handleCreateToggle = () => {
  //   setToggle(false);
  // };

  // const handleShowWebinarListToggle = () => {
  //   setToggle(true);
  // };
  const { activeCaseTab, setActiveTabCase } = useModalContext();
  const handleCreateCaseTab = () => {
    setActiveTabCase(1);
  };
  const handleCaseListTab = () => {
    setActiveTabCase(2);
  };
  const handleCaseTrashTab = () => {
    setActiveTabCase(3);
  };
  const handleCaseDraftTab = () => {
    setActiveTabCase(4);
  };
  return (
    <div className="ir-ws-webinar-admin-custom-tab">
      <p
        onClick={handleCreateCaseTab}
        className={`ir-ws-custom-tab-button w-[165px] text-center  ${
          activeCaseTab === 1 ? "" : "ir-ws-custom-tab-active"
        }`}
      >
        Cases
      </p>
      <p
        className={`ir-ws-custom-tab-button w-[165px] text-center ${
          activeCaseTab === 2 ? "" : "ir-ws-custom-tab-active"
        }`}
        onClick={handleCaseListTab}
      >
        Cases List
      </p>
      <p
        className={`ir-ws-custom-tab-button w-[165px] text-center ${
          activeCaseTab === 3 ? "" : "ir-ws-custom-tab-active"
        }`}
        onClick={handleCaseTrashTab}
      >
        Trash
      </p>
      <p
        className={`ir-ws-custom-tab-button w-[165px] text-center ${
          activeCaseTab === 4 ? "" : "ir-ws-custom-tab-active"
        }`}
        onClick={handleCaseDraftTab}
      >
        Draft
      </p>
    </div>
  );
};

export default CaseAdminContent;
