import React from "react";
import AdminCardComponent from "../AdminComponent/AdminCardComponent";
// import caseIcon from "../../../assets/images/case-icon.png";
// import courseIcon from "../../../assets/images/course-icon.png";
import caseIcon from "../../../assets/images/case-ina.png";
import courseIcon from "../../../assets/images/course-ina.png";
import webinarIcon from "../../../assets/images/webinar-ina.png";
import chatIcon from "../../../assets/images/chat-ina.png";

import AdminDataComponent from "../AdminComponent/AdminDataComponent";
import AdminProductComponent from "../AdminComponent/AdminProductComponent";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";

const DashboardPanel = () => {
  const { courses, cases, webinarData } = useModalContext();
  const activeWebinar = webinarData?.filter(
    (item) => item.status !== "completed"
  ).length;
  return (
    <div className="flex flex-col gap-[30px] xl:gap-[12px] 3xl:gap-[20px] h-[100%]  ">
      <div className="flex items-start justify-between pt-[18px] xl:pt-[12px] 3xl:pt-[20px] w-[100%] gap-[20px] xl:gap-[12px] 3xl:gap-[20px] h-[18%] xl:h-[18%] ">
        <AdminCardComponent
          imgSrc={chatIcon}
          type={"Chat Cases"}
          total={"19"}
          active={"17"}
        />
        <AdminCardComponent
          imgSrc={courseIcon}
          type={"Courses"}
          total={courses.length ? courses.length : "0"}
          active={courses.length ? courses.length : "0"}
        />
        <AdminCardComponent
          imgSrc={caseIcon}
          type={"Cases"}
          total={cases.length ? cases.length : "0"}
          active={cases.length ? cases.length : "0"}
        />
        <AdminCardComponent
          imgSrc={webinarIcon}
          type={"Webinar"}
          total={webinarData.length ? webinarData.length : "0"}
          active={activeWebinar !== undefined ? activeWebinar : "0"}
        />
      </div>
      <div className="flex  gap-[30px] xl:gap-[12px] 3xl:gap-[20px] xl:h-[37%] 3xl:h-[34%]">
        <AdminDataComponent
          type="Product List"
          title="Product Name"
          data="Type"
          status="Status"
          dataInfo="Sales Info"
        />
        <AdminProductComponent type="Create" />
      </div>
      <div className="flex  gap-[30px] xl:gap-[12px] 3xl:gap-[20px] ir-ad-pn-last-cont">
        <AdminProductComponent type="Sales" />
        <AdminDataComponent
          type="Users"
          title="User ID"
          status="Role"
          data="Name"
          dataInfo="Mail ID"
        />
      </div>
    </div>
  );
};

export default DashboardPanel;
