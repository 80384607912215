import React from "react";

const AdminCardComponent = ({ imgSrc, type, total, active }) => {
  const typeColors = {
    "Chat Cases": "#FB9258",
    Courses: "#8FE5FA",
    Cases: "#D3D242",
    Webinar: "#A089FD",
  };

  return (
    <div className="w-[25%] ph-box-shadow  h-[100%]">
      <div className="bg-[#FFFFFF]  rounded-[8px] h-[100%] text-[#1C1D26]">
        <div className="p-[12px] relative xl:p-[12px] 3xl:p-[20px] flex flex-col justify-between h-[100%]">
          <div className="flex flex-col justify-between xl:gap-[10px] h-[100%]">
            <p
              className="xl:text-[14px] font-[700] 3xl:text-[16px]"
              style={{ color: typeColors[type] || "#FB9258" }}
            >
              {type}
            </p>
            <p className="xl:text-[12px] 3xl:text-[14px] font-[600] text-[#0C0B18]">
              Total {type} : {total}
            </p>
            <p className="xl:text-[12px] 3xl:text-[14px] font-[600] text-[#0C0B18]">
              Active {type} : {active}
            </p>
          </div>
          <div
            className="absolute top-[12px] xl:top-[12px] right-[12px] xl:right-[12px] 3xl:top-[24px] 3xl:right-[24px] w-[40px] h-[40px] xl:w-[36px] xl:h-[36px] 3xl:w-[48px] 3xl:h-[48px]  rounded-[4px] flex items-center justify-center"
            style={{ background: typeColors[type] || "#FB9258" }}
          >
            <img
              src={imgSrc}
              className="w-[24px] xl:w-[24px] 3xl:w-[32px]"
              alt="icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCardComponent;
