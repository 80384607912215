import { useContext, useEffect, useState } from "react";
import { createContext } from "react";
import axios from "axios";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { useMeetingManager } from "amazon-chime-sdk-component-library-react";

import Swal from "sweetalert2";
import { RootStore } from "../../store/store";
const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const meetingManager = useMeetingManager();
  const [activeTab, setActiveTab] = useState(1);

  const [title, setTitle] = useState("");
  const [dateAndTime, setDateAndTime] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [file, setFile] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [webinarData, setWebinarData] = useState([]);
  const [transacationDetails, setTransactionDetails] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [bookNowModal, setBookNowModal] = useState(false);
  const [successfulWebinarModal, setSuccessfulWebinarModal] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isbookLoading, setBookloading] = useState(false);
  const [isJoin, setIsjoin] = useState(false);
  const [isPresenterjoin, setIspresenterjoin] = useState(false);
  const [meetingId, setMeetingId] = useState("");
  const [videoTiles, setVideoTiles] = useState(false);
  const [copyText, setCopyText] = useState("");
  const [webinarTitle, setWebinarTitle] = useState("");
  const [urlMeetingId, setUrlMeetingId] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [occupation, setOccupation] = useState("");
  const [passCode, setPassCode] = useState("");
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [userId, setUserId] = useState("");
  const [activeAltTitle, setActiveAltTitle] = useState("Webinar");
  const [userList, setUserList] = useState([]);
  const [runUseEffect, setRunUseEffect] = useState(false);
  const [createCourseOpen, setCreatecourseOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [adminTransactionData, setAdminTransactionData] = useState([]);
  const [userTransactionData, setUserTransactionData] = useState([]);
  const [createCaseOpen, setCreateCaseOpen] = useState(false);
  const [activeTabCourse, setActiveTabCourse] = useState(1);
  const [deletedCourses, setDeletedCourses] = useState([]);
  const [selectedCourseIds, setSelectedCourseIds] = useState([]);
  const [courseUpdateId, setUpdateCourseId] = useState();
  const [userDetail, setUserDetail] = useState(null);
  const [userData, setUserData] = useState(null);
  const [activeCaseTab, setActiveTabCase] = useState(1);
  const [allCourseTransaction, setAllCourseTransactions] = useState([]);
  const [isPaymentLoading, setIspaymentLoading] = useState(false);
  const navigate = useNavigate();

  const [cases, setCases] = useState([]);
  const [deletedCases, setDeletedCases] = useState([]);
  const [caseUpdateId, setUpdateCaseId] = useState();
  const [updateDraftCaseId, setUpdateDraftCaseId] = useState();
  const [allCaseTransaction, setAllCaseTransactions] = useState([]);
  const [draftCourseUpdateId, setDraftCourseUpdateId] = useState(null);
  const [allDraftCases, setAlldraftCases] = useState([]);

  const [isSearchOverlayOpen, setIsSearchOverlayOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);

  const [chatImageEditURL, setChatImageEditURL] = useState(null);
  const [chatImageEditedResult, setChatImageEditedResult] = useState(null);
  const [isImageEditorOpen, setIsImageEditorOpen] = useState(false);

  const [isGroupInfoOverlayOpen, setIsGroupInfoOverlayOpen] = useState(false);
  const [isAllMediaOverlayOpen, setIsAllMediaOverlayOpen] = useState(false);
  const [showAllVoters, setShowAllVoters] = useState(false);
  const [currentPollId, setCurrentPollId] = useState(null);
  const [isScrollToBottom, setIsScrollToBottom] = useState(false);
  const [activeChatRoomGlobal, setActiveChatRoomGloabal] = useState(null);
  const [isSynopsisVisible, setIsSynopsisVisible] = useState(false);
  const [synopsisData, setSynopsisData] = useState(null);
  const [isViewMoreMenuOpen, setIsViewMoreMenuOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [activeRoomsData, setActiveRoomsData] = useState([]);
  //   const [searchTerm, setSearchTerm] = useState("");
  const [chatSidebarOpen, setChatSideBarOpen] = useState(true);
  const [chatSidebarOpt, setChatSidebarOpt] = useState(false);
  const [chatSearchActive, setChatSearchActive] = useState(false);
  const [chatProfileOpen, setChatProfileOpen] = useState(false);

  ///////Admin Dashboard All State Starts////////////////
  const [adminPanelType, setAdminPanelType] = useState("Dashboard");
  const [createWebinar, setCreateWebinar] = useState(false);

  ///////Admin Dashboard All State End////////////////

  ///////Admin Dashboard All Functionality Starts////////////////

  const handleSelectPanel = (type) => {
    setAdminPanelType(type);
  };

  const handleWebinarCreateModalOpen = () => {
    setModalOpen(true);
  };
  ///////Admin Dashboard All Functionality End////////////////

  const handleChatProfileOpen = () => {
    setChatProfileOpen(!chatProfileOpen);
  };

  const handleChatSearch = () => {
    setChatSearchActive(!chatSearchActive);
  };
  const handleToggleChatSideBarOpt = () => {
    setChatSidebarOpt(!chatSidebarOpt);
  };
  const handleSidebar = () => {
    setChatSideBarOpen(!chatSidebarOpen);
  };
  const handleViewVoters = (pollId) => {
    setCurrentPollId(pollId);
    setShowAllVoters(true);
    setIsAllMediaOverlayOpen(false);
    setIsSearchOverlayOpen(false);
    setIsGroupInfoOverlayOpen(false);
  };

  const closeAllVoters = () => {
    setCurrentPollId(null);
    setShowAllVoters(false);
  };

  const toggleSearchOverlay = () => {
    setIsSearchOverlayOpen((prev) => !prev);
    setIsGroupInfoOverlayOpen(false);
    setIsAllMediaOverlayOpen(false);
    setSearchTerm("");
    setCurrentPollId(null);
    setShowAllVoters(false);
  };
  const toggleGroupInfoOverlay = () => {
    setIsGroupInfoOverlayOpen(true);
    setIsSearchOverlayOpen(false);
    setIsAllMediaOverlayOpen(false);
    setCurrentPollId(null);
    setShowAllVoters(false);
  };
  const handleGroupInfoClick = () => {
    toggleGroupInfoOverlay();
  };
  const toggleAllMediaOverlay = () => {
    setIsAllMediaOverlayOpen(true);
    setIsSearchOverlayOpen(false);
    setIsGroupInfoOverlayOpen(false);
    setCurrentPollId(null);
    setShowAllVoters(false);
  };
  const closeAllMediaOverlay = () => {
    setIsAllMediaOverlayOpen(false);
    setIsGroupInfoOverlayOpen(true);
  };

  const [messageRefs, setMessageRefs] = useState({});

  const BASE_URL = "https://webinar-staging.backend.ir4u.info";
  let location = useLocation();
  let locationUrl = window.location.origin;

  const token = localStorage.getItem("jwtTokenApi1");

  function convertLocalToUTC(localDateTimeString) {
    const localDateTime = new Date(localDateTimeString);

    const utcTimeString = localDateTime.toISOString();

    return utcTimeString;
  }

  const handleCreateCourse = () => {
    setCreatecourseOpen(!createCourseOpen);
    setUpdateCourseId(null);
  };
  const handleCreatecourspage = () => {
    setCreatecourseOpen(true);
  };
  const handleCreateCase = () => {
    setCreateCaseOpen(true);
    setUpdateCaseId(null);
    setDraftCourseUpdateId(null);
    setUpdateDraftCaseId(null);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const handleSaveAndContinue = () => {
    setCurrentPage(2);
  };
  const [caseCurrentPage, setCaseCurrentPage] = useState(1);
  const handlePreviewCasePage = () => {
    setCaseCurrentPage(2);
  };

  const inputField = [
    {
      id: 1,
      title: "Date and Time",
      type: "datetime-local",
      state: dateAndTime,
      setState: setDateAndTime,
    },
    {
      id: 2,
      title: "Webinar Name",
      type: "text",
      state: title,
      setState: setTitle,
    },
    {
      id: 3,
      title: "Description",
      type: "text",
      state: description,
      setState: setDescription,
    },
    {
      id: 4,
      title: "Set Price",
      type: "text",
      state: price,
      setState: setPrice,
    },
  ];

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setFile("");
    setDateAndTime("");
    setTitle("");
    setDescription("");
    setPrice("");
    setIsEditorOpen(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      setSelectedImage(imageUrl);
      setIsEditorOpen(true);
    }
    setFile(selectedFile);
  };

  const handleCancel = () => {
    setIsEditorOpen(false);
    setSelectedImage(null);
  };

  // useEffect(() => {
  //   // console.log(file);
  //   console.log(webinarData);
  //   // console.log(price);
  //   // console.log(dateAndTime);
  //   // console.log(meetingId);
  // }, [webinarData]);
  // //Create a webinar

  // const handleSubmit = async () => {
  //   if (!title || !dateAndTime || !price || !description) return;
  //   try {
  //     setIsLoading(true);
  //     const utcDateAndTime = convertLocalToUTC(dateAndTime);
  //     const defaultThumbnail = "https://i.ibb.co/WnJr2Mq/Selected-photo.jpg";
  //     const formData = {
  //       dateAndTime: utcDateAndTime,
  //       title,
  //       description,
  //       thumbnail: thumbnail || defaultThumbnail,
  //       price: +price.replace(/\D/g, ""),
  //       currency: "USD",
  //       offer: "10",
  //       noOfSeats: 250,
  //     };

  //     console.log(formData);
  //     const response = await axios.post(
  //       `${BASE_URL}/api/v1/webinar/admin`,
  //       formData,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     // console.log(response);

  //     const updatedResponse = await axios.get(`${BASE_URL}/api/v1/webinars`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     setWebinarData(updatedResponse.data);
  //     setDateAndTime("");
  //     setTitle("");
  //     setDescription("");
  //     setPrice("");
  //     setFile("");
  //     closeModal();
  //     setThumbnail("");
  //     if (response.status === 200) {
  //       Swal.fire("Success", "Webinar created successfully ", "success");
  //     }
  //   } catch (error) {
  //     console.error("Error making API request:", error);
  //     if (error.response.status !== 200) {
  //       Swal.fire("Error", "Kindly choose future dates", "error");
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSubmit = async () => {
    if (!title || !dateAndTime || !price || !description) return;
    try {
      setIsLoading(true);
      const utcDateAndTime = convertLocalToUTC(dateAndTime);
      const defaultThumbnail = "https://i.ibb.co/WnJr2Mq/Selected-photo.jpg";
      const formData = {
        dateAndTime: utcDateAndTime,
        title,
        description,
        thumbnail: thumbnail || defaultThumbnail,
        price: +price.replace(/\D/g, ""),
        currency: "USD",
        offer: "10",
        noOfSeats: 250,
      };

      console.log(formData);

      let role = "admin";
      if (userDetail?.role?.includes("admin")) {
        role = "admin";
      } else if (userDetail?.role?.includes("publisher")) {
        role = "publisher";
      }

      console.log("User role:", role);

      // Determine API endpoint based on user role
      const endpoint =
        role === "publisher"
          ? `${BASE_URL}/api/v1/sub-admin/create-webinar`
          : `${BASE_URL}/api/v1/webinar/admin`;

      const response = await axios.post(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      // console.log(response);

      const updatedResponse = await axios.get(`${BASE_URL}/api/v1/webinars`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWebinarData(updatedResponse.data);
      setDateAndTime("");
      setTitle("");
      setDescription("");
      setPrice("");
      setFile("");
      closeModal();
      setThumbnail("");
      if (response.status === 200) {
        Swal.fire("Success", "Webinar created successfully ", "success");
      }
    } catch (error) {
      console.error("Error making API request:", error);
      if (error.response.status !== 200) {
        Swal.fire("Error", "Kindly choose future dates", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // const handleSubmit = async () => {
  //   if (!title || !dateAndTime || !price || !description) return;
  //   try {
  //     setIsLoading(true);
  //     const utcDateAndTime = convertLocalToUTC(dateAndTime);
  //     const defaultThumbnail = "https://i.ibb.co/WnJr2Mq/Selected-photo.jpg";
  //     const formData = {
  //       dateAndTime: utcDateAndTime,
  //       title,
  //       description,
  //       thumbnail: thumbnail || defaultThumbnail,
  //       price: +price.replace(/\D/g, ""),
  //       currency: "USD",
  //       offer: "10",
  //       noOfSeats: 250,
  //     };

  //     console.log(formData);

  //     let role = "user"; // Default role
  //     if (userDetail?.role?.includes("admin")) {
  //       role = "admin";
  //     } else if (userDetail?.role?.includes("publisher")) {
  //       role = "publisher";
  //     }

  //     console.log("User role:", role);

  //     // Determine API endpoint based on user role
  //     const endpoint =
  //       role === "publisher"
  //         ? `${BASE_URL}/api/v1/sub-admin/create-webinar`
  //         : `${BASE_URL}/api/v1/webinar/admin`;

  //     const response = await axios.post(endpoint, formData, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     if (response.status === 200) {
  //       const updatedResponse = await axios.get(`${BASE_URL}/api/v1/webinars`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       setWebinarData(updatedResponse.data);
  //       setDateAndTime("");
  //       setTitle("");
  //       setDescription("");
  //       setPrice("");
  //       setFile("");
  //       closeModal();
  //       setThumbnail("");
  //       Swal.fire("Success", "Webinar created successfully", "success");
  //     }
  //   } catch (error) {
  //     console.error("Error making API request:", error);
  //     if (error.response?.status !== 200) {
  //       Swal.fire("Error", "Kindly choose future dates", "error");
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  //Get all the Webinar

  useEffect(() => {
    const fetchWebinarData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${BASE_URL}/api/v1/webinars`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setWebinarData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchWebinarData();
  }, [setWebinarData, setIsLoading, token]);

  //Get user transaction details
  useEffect(() => {
    const fetchTransactionDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${BASE_URL}/api/v1/webinar/user/transactions`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(response);
        setTransactionDetails(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchTransactionDetails();
  }, []);

  //Join the meeting from webinar list on click of join button in webinar list
  const handleJoinMeeting = async (webinarId) => {
    try {
      console.log(webinarId);
      const response = await axios.post(
        `${BASE_URL}/api/v1/meetings/create?webinarId=${webinarId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      const hostAttendeeDetails = response.data.hostAttendeeDetails;
      const meetingDetails = response.data.meetingDetails;
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        meetingDetails,
        hostAttendeeDetails
      );
      await meetingManager.join(meetingSessionConfiguration);
      await meetingManager.start();
      setVideoTiles(true);
      navigate("/meeting");

      const updatedResponse = await axios.get(`${BASE_URL}/api/v1/webinars`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      const webinar = updatedResponse.data.find(
        (webinar) => webinar.id === webinarId
      );

      if (webinar) {
        setWebinarTitle(webinar.title);
        setUrlMeetingId(webinar.meetingId);
        setWebinarData(updatedResponse.data);
      } else {
        console.log("Webinar not found in the updated response.");
      }
      setWebinarData(updatedResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  //Leave the meeting
  const leaveMeeting = async (webinarId) => {
    try {
      await meetingManager.leave();
      const response = await axios.put(
        `${BASE_URL}/api/v1/webinar/admin?webinarId=${webinarId}`,
        {
          status: "completed",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      setUsername("");
      setRole("");
      setPassCode("");
    } catch (error) {
      console.error(error);
    }
  };

  //Copy Presenter Url from webinar list
  const handleCopyPresenterUrl = (joinUrl) => {
    const link = `${locationUrl}${joinUrl}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("Text successfully copied to clipboard");
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard", err);
      });
  };

  //Join the meeting with copied url from webinar list for the presenter
  const handleJoinPresenterUrl = async (joinUrl) => {
    setIspresenterjoin(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/webinar/join/${joinUrl}?username=${username}&passcode=${passCode}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (!response.data) {
        console.error("No data received from the server.");
        return;
      }
      setMeetingId(response.data.meetingDetails.meetingId);
      const hostAttendeeDetails = response.data.attendeeDetails;
      const meetingDetails = response.data.meetingDetails;
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        meetingDetails,
        hostAttendeeDetails
      );
      setIspresenterjoin(false);
      await meetingManager.join(meetingSessionConfiguration);
      await meetingManager.start();
      setVideoTiles(true);
    } catch (error) {
      // console.log(error);
      setIspresenterjoin(false);
      if (error.response.status === 502) {
        Swal.fire("Error", "Webinar has been expired/completed");
      }
      if (error.response.status === 400) {
        Swal.fire("Meeting has been ended");
      }
    }
  };
  // //Log out after expired jwt token
  const handleLogout = () => {
    localStorage.removeItem("jwtTokenApi1");
    localStorage.removeItem("jwtTokenApi1");
    localStorage.removeItem("loggedInUsername");
    localStorage.removeItem("userProfile");
    localStorage.removeItem("registeredUsername");
    localStorage.setItem("isLoggedIn", false);
    localStorage.setItem("isRegistered", false);

    navigate("/");
  };

  // useEffect(() => {
  //   const checkTokenExpiration = () => {
  //     try {
  //       const payloadBase64 = token?.split(".")[1];
  //       const payload = JSON.parse(atob(payloadBase64));

  //       if (!payload || !payload.exp) {
  //         return false;
  //       }

  //       const currentTime = Math.floor(Date.now() / 1000);
  //       // console.log(payload.exp, currentTime);
  //       if (payload.exp < currentTime) {
  //         handleLogout();
  //       }
  //     } catch (error) {
  //       console.error("Error decoding JWT:", error);
  //       return false;
  //     }
  //   };

  //   checkTokenExpiration();

  //   const intervalId = setInterval(checkTokenExpiration, 10000);
  //   return () => clearInterval(intervalId);
  // }, [token]);

  //Register of an user and sending meeting link to mail ID on click of Book Now button in Homepage

  const handleRegisterUserMeeting = async (webinarId) => {
    setBookloading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/webinar/user/register?webinarId=${webinarId}`,
        {},
        {
          headers: {
            apphost: locationUrl,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setBookloading(false);
        Swal.fire("Success", "Successfully registered to webinar", "success");
      }

      const response1 = await axios.get(
        `${BASE_URL}/api/v1/webinar/user/transactions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      setTransactionDetails(response1.data);
      const response3 = await axios.get(`${BASE_URL}/api/v1/webinars`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setWebinarData(response3.data);
    } catch (error) {
      setBookloading(false);
      console.log(error);
    }
  };

  //Join the meeting for user with url provided through mail
  const handleJoinUserUrl = async (joinUrl) => {
    setIsjoin(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/v1/webinar/attendee/join/${joinUrl}?username=${username}&passcode=${passCode}&role=${role}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setIsjoin(false);
        setRunUseEffect(false);
      }
      const hostAttendeeDetails = response.data.attendeeDetails;
      const meetingDetails = response.data.meetingDetails;
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        meetingDetails,
        hostAttendeeDetails
      );

      await meetingManager.join(meetingSessionConfiguration);
      await meetingManager.start();
      setVideoTiles(true);
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) {
        setIsjoin(false);
        Swal.fire("", "Presenter has not joined the meeting yet");
      }
      if (error.response.status === 502) {
        setIsjoin(false);
        Swal.fire("", "Webinar has been expired/completed");
      }
    }
  };

  //Join meeting for user with the url provided by the presenter
  const handleJoinMeetingUrl = async (meetingId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/attendee/${meetingId}/join?username=${username}&role=${role}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (!response.data) {
        console.error("No data received from the server.");
        return;
      }
      const hostAttendeeDetails = response.data.attendeeInfo;
      const meetingDetails = response.data.meetingDetails.hostMeetingDetails;
      if (!meetingDetails) {
        console.error("Meeting details are not available.");
        return;
      }
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        meetingDetails,
        hostAttendeeDetails
      );
      await meetingManager.join(meetingSessionConfiguration);
      await meetingManager.start();

      const updatedResponse = await axios.get(`${BASE_URL}/api/v1/webinars`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
      const webinar = updatedResponse.data.find(
        (webinar) => webinar.meetingId === meetingId
      );
      if (webinar) {
        setWebinarTitle(webinar.title);
      } else {
        console.log("Webinar not found in the updated response.");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleUserJoinMeeting = async (meetingId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/attendee/${meetingId}/create`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);

      if (!response.data) {
        console.error("No data received from the server.");
        return;
      }

      const hostAttendeeDetails = response.data.attendeeInfo;
      const meetingDetails = response.data.meetingDetails.hostMeetingDetails;

      if (!meetingDetails) {
        console.error("Meeting details are not available.");
        return;
      }

      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        meetingDetails,
        hostAttendeeDetails
      );

      await meetingManager.join(meetingSessionConfiguration);
      navigate("/user-meeting");
    } catch (error) {
      console.log(error);
    }
  };

  //Delete the webinar from webinar list
  const handleDeleteWebinar = async (webinarId) => {
    try {
      setIsLoading(true);
      const response = await axios.delete(
        `${BASE_URL}/api/v1/webinar/admin?webinarId=${webinarId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWebinarData((data) =>
        data?.filter((webinar) => webinar.id !== webinarId)
      );
      if (response.status === 200) {
        Swal.fire("Success", "Webinar has been deleted", "success");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  // Cancel Webinar from webinar list

  const handleCancelWebinar = async (webinarId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/v1/webinar/admin/cancel?webinarId=${webinarId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedResponse = await axios.get(`${BASE_URL}/api/v1/webinars`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        Swal.fire("Success", "Webinar has been cancelled", "success");
      }
      setWebinarData(updatedResponse.data);
    } catch (error) {
      console.error(error);
    }
  };

  //Update the webinar from webinar list
  const handleUpdateWebinar = async (webinarId) => {
    try {
      setIsLoading(true);
      const utcDateAndTime = convertLocalToUTC(dateAndTime);
      const updatedWebinarData = {
        dateAndTime: utcDateAndTime,
        title,
        description,
        thumbnail,
        price: +price.replace(/\D/g, ""),
        currency: "USD",
        offer: "10",
        noOfSeats: 250,
      };
      const response = await axios.put(
        `${BASE_URL}/api/v1/webinar/admin?webinarId=${webinarId}`,
        updatedWebinarData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setWebinarData((prevWebinars) =>
        prevWebinars.map((webinar) =>
          webinar.id === webinarId ? updatedWebinarData : webinar
        )
      );
      const updatedResponse = await axios.get(`${BASE_URL}/api/v1/webinars`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        Swal.fire("Success", "Webinar has been updated", "success");
      }
      setWebinarData(updatedResponse.data);
      setDateAndTime("");
      setTitle("");
      setDescription("");
      setPrice("");
      setFile("");
    } catch (error) {
      console.error("Error making API request:", error);
      if (error.response.status !== 200) {
        Swal.fire("Error", "Kindly choose future dates", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  //Get user profile details
  // useEffect(() => {
  //   const userProfileDetails = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://backend.ir4u.info/api/v1/user/profile`,
  //         {
  //           headers: {
  //             Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //           },
  //         }
  //       );
  //       // console.log("User profile from 1st api response", response);
  //       setUserId(response.data.id);
  //       setUserDetail(response?.data);

  //       // Convert object to a JSON string before storing
  //       localStorage.setItem(
  //         "userProfile",
  //         JSON.stringify(response?.data?.role)
  //       );
  //       // if (response.data?.isEmailVerified) {
  //       //   setEmailVerified(true);
  //       // }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   userProfileDetails();
  // }, []);

  useEffect(() => {
    const userProfileDetails = async () => {
      try {
        const token = localStorage.getItem("jwtTokenApi1");
        console.log("JWT Token:", token); // Debugging step

        const response = await axios.get(
          `https://backend.ir4u.info/api/v1/user/profile`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        console.log("API Response:", response.data);

        setUserId(response.data.id);
        setUserDetail(response.data);

        if (response?.data) {
          console.log("Role before storing:", response.data.role);
          localStorage.setItem(
            "userProfile",
            JSON.stringify(response.data.role)
          );
        } else {
          console.warn("Role is undefined or null.");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    userProfileDetails();
  }, []);

  // console.log("userDetail", userDetail);
  useEffect(() => {
    const handleGetAllUser = async () => {
      try {
        const response = await axios.get(
          "https://backend.ir4u.info/api/v1/admin/all/users",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        // console.log(response.data);
        setUserList(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    handleGetAllUser();
  }, []);

  //Delete User from admin page
  //   const handleDeleteUser = async (id) => {
  //     try {
  //       const response1 = await axios.delete(
  //         `https://backend.ir4u.info/api/v1/user/${id}`,
  //         {
  //           headers: {
  //             Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //           },
  //         }
  //       );
  //       // if (response1.status === 200) {
  //       //   Swal.fire("Success", "User has been deleted", "success");
  //       // }
  //       const response = await axios.get(
  //         "https://backend.ir4u.info/api/v1/admin/all/users",
  //         {
  //           headers: {
  //             Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
  //           },
  //         }
  //       );
  //       // console.log(response.data);
  //       setUserList(response.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  const handleDeleteUser = async (id) => {
    try {
      const response1 = await axios.delete(
        `https://backend.ir4u.info/api/v1/user/${id}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      if (response1) {
        const response = await axios.get(
          "https://backend.ir4u.info/api/v1/admin/all/users",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        // console.log(response.data);
        setUserList(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Send link for email vefication

  const sendVerifyEmailLink = async (username) => {
    try {
      const response = await axios.get(
        `https://backend.ir4u.info/api/v1/send/verification?username=${username}`,
        {
          headers: {
            apphost: window.location.origin,
          },
        }
      );
      if (response.status === 200) {
        Swal.fire("Success", "Verification link sent to Email", "success");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyEmail = async (token, email) => {
    try {
      const response = await axios.get(
        `https://backend.ir4u.info/api/v1/verify?token=${token}&email=${email}`
      );
      if (response.status === 200) {
        Swal.fire("Success", "Email has been verified", "success");
        navigate("/");
      }
    } catch (error) {
      if (error.response.status !== 200) {
        Swal.fire("Error", "Verification failed", "error");
      }
    }
  };
  // console.log(userDetail.email);
  const handlePaymentCheckout = async (webinarId) => {
    setIspaymentLoading(true);
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        `https://webinar-staging.backend.ir4u.info/api/v1/payment/create-checkout-session`,
        {
          webinarId: webinarId,
          userEmail: userDetail.email,
          successUrl: `${window.location.origin}/success`,
          cancelUrl: `${window.location.origin}/cancel`,
        },
        { headers }
      );
      console.log(response);

      const { sessionId, url } = response.data;

      if (response.status === 200) {
        window.location.href = url;
      }
    } catch (error) {
      console.error("Error making API call:", error);
    } finally {
      setIspaymentLoading(false);
    }
  };
  useEffect(() => {
    const fetchUserTransactionDetails = async () => {
      try {
        // setIsLoading(true);
        const response = await axios.get(
          `https://webinar-staging.backend.ir4u.info/api/v1/payment/transactions?page=1&pageSize=10`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        console.log(response);
        // setUserTransactionData(response.data);
        // setTransactionDetails(response.data);
        // setIsLoading(false);
        const data = response.data;

        // Handle case when data is null
        if (data === null) {
          setUserTransactionData([]); // Set an empty array if the response is null
        } else {
          setUserTransactionData(data); // Set the actual data if available
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserTransactionDetails();
  }, []);

  const courseTransactionDetails = async (userId) => {
    try {
      const response = await axios.get(
        `https://courses.ir4u.info/api/transactions/user/${userId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      if (response.status == 200) {
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchAllCoursetrnasactions = async () => {
      try {
        const res = await axios.get(
          "https://courses.ir4u.info/api/all/transactions",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        if (res.status == 200) {
          // console.log("this is the response of all transaction", res.data);
          setAllCourseTransactions(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllCoursetrnasactions();
  }, []);

  useEffect(() => {
    const fetchAdminTransactionDetails = async () => {
      try {
        // setIsLoading(true);
        const response = await axios.get(
          `https://webinar-staging.backend.ir4u.info/api/v1/payment/admin/transactions`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        // console.log(response);
        setAdminTransactionData(response.data);
        // setTransactionDetails(response.data);
        // setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchAdminTransactionDetails();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://courses.ir4u.info/api/all/courses"
        );
        // setCourses(response.data.reverse());
        // console.log(response);
        const filteredCourses = response.data.filter(
          (course) => !course.formData.isDeleted
        );
        console.log("filteredCourses", filteredCourses);
        setCourses(filteredCourses.reverse());
        const deletedCoursesArray = response.data.filter(
          (course) => course.formData.isDeleted
        );
        setDeletedCourses(deletedCoursesArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const deleteCourseFunction = async (id) => {
    try {
      // Make a DELETE request to the API endpoint
      const response = await axios.post(
        `https://courses.ir4u.info/api/course/delete/${id}`
      );

      // Check if the request was successful
      const response2 = await axios.get(
        "https://courses.ir4u.info/api/all/courses"
      );
      if (response.status === 200) {
        // Course deleted successfully
        console.log("Course deleted successfully.");
        Swal.fire("Success", "Course has been deleted", "success");
        setCourses((prevCourses) =>
          prevCourses.filter((course) => course._id !== id)
        );

        const deletedCoursesArray = response2.data.filter(
          (course) => course.formData.isDeleted
        );
        setDeletedCourses(deletedCoursesArray);
      } else {
        // Handle other status codes
        console.error("Failed to delete course. Status:", response.status);
      }
    } catch (error) {
      // Handle error
      console.error("Error deleting course:", error);
    }
  };

  const restoreCourseFunction = async (courseId) => {
    try {
      const response = await axios.post(
        "https://courses.ir4u.info/api/course/restore",
        {
          courseIds: [courseId],
        }
      );

      if (response.status === 200) {
        Swal.fire("Success", "Course has been restored", "success");
        setDeletedCourses((prevCourses) =>
          prevCourses.filter((course) => course._id !== courseId)
        );
        const updatedResponse = await axios.get(
          "https://courses.ir4u.info/api/all/courses"
        );
        const filteredCourses = updatedResponse.data.filter(
          (course) => !course.formData.isDeleted
        );
        setCourses(filteredCourses.reverse());
      }
    } catch (error) {
      console.error("Error restoring course:", error);
    }
  };
  const handleDeleteMultipleCourses = async () => {
    try {
      await axios.post("https://courses.ir4u.info/api/course/delete", {
        courseIds: selectedCourseIds,
      });
      const response = await axios.get(
        "https://courses.ir4u.info/api/all/courses"
      );
      if (response.status === 200) {
        const filteredCourses = response.data.filter(
          (course) => !course.formData.isDeleted
        );
        const deletedCoursesArray = response.data.filter(
          (course) => course.formData.isDeleted
        );
        setDeletedCourses(deletedCoursesArray);
        setCourses(filteredCourses.reverse());
        setSelectedCourseIds([]);
        Swal.fire("Success", "Course has been deleted", "success");
      }
    } catch (error) {
      console.error("Error deleting courses:", error);
    }
  };

  const isUpdateCourse = (data) => {
    setUpdateCourseId(data);
  };

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await axios.get(
          "https://case.ir4u.info/api/v1/admin/case/all",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
            },
          }
        );

        // Filter out cases where isDeleted is true
        const filteredCases = response?.data.data.filter(
          (caseItem) => !caseItem.isDeleted
        );
        console.log("filtered Cases data:", filteredCases);
        setCases(filteredCases);
        // Store deleted cases
        const deletedCasesArray = response?.data.data.filter(
          (caseItem) => caseItem.isDeleted
        );
        setDeletedCases(deletedCasesArray);
        // console.log("Deleted cases: " + deletedCasesArray);
      } catch (error) {
        console.error("Error fetching cases:", error);
      }
    };

    fetchCases();
  }, []);

  const fetchDraftData = async () => {
    try {
      const response = await axios.get(
        "https://case.ir4u.info/api/v1/admin/case/all/draft",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
          },
        }
      );
      const draftCasesArry = response.data.draftCases;
      console.log("this is the draft response", draftCasesArry);
      const draftCases = draftCasesArry.map((caseObj) => ({
        id: caseObj._id,
        title: caseObj.slides[0]?.name,
      }));
      setAlldraftCases(draftCases);
      console.log("Extracted cases:", allDraftCases);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchDraftData();
  }, []);

  const deleteCaseFunction = async (id) => {
    try {
      const response = await axios.post(
        `https://case.ir4u.info/api/v1/admin/case/delete/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
          },
        }
      );

      // Fetch the updated list of cases
      const response2 = await axios.get(
        "https://case.ir4u.info/api/v1/admin/case/all",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Case deleted successfully.");
        Swal.fire("Success", "Case has been deleted", "success");
        setCases((prevCases) =>
          prevCases.filter((caseItem) => caseItem._id !== id)
        );

        const deletedCasesArray = response2.data.data.filter(
          (caseItem) => caseItem.isDeleted
        );
        console.log("deletedCasesArray from delete func: " + deletedCasesArray);
        setDeletedCases(deletedCasesArray);
      } else {
        console.error("Failed to delete case. Status:", response.status);
      }
    } catch (error) {
      console.error("Error deleting case:", error);
    }
  };

  const restoreCaseFunction = async (id) => {
    try {
      const response = await axios.post(
        `https://case.ir4u.info/api/v1/admin/case/restore/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Case restored successfully.");
        Swal.fire("Success", "Case has been restored", "success");

        // Fetch updated cases and deleted cases
        const response2 = await axios.get(
          "https://case.ir4u.info/api/v1/admin/case/all",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
            },
          }
        );

        // Filter out cases where isDeleted is true
        const filteredCases = response2?.data.data.filter(
          (caseItem) => !caseItem.isDeleted
        );
        setCases(filteredCases);

        // Store deleted cases
        const deletedCasesArray = response2?.data.data.filter(
          (caseItem) => caseItem.isDeleted
        );
        setDeletedCases(deletedCasesArray);
      } else {
        console.error("Failed to restore case. Status:", response.status);
      }
    } catch (error) {
      console.error("Error restoring case:", error);
    }
  };

  const isUpdateCase = (caseId) => {
    setUpdateCaseId(caseId);
  };

  const isUpdateDraftCaseId = (caseId) => {
    setUpdateDraftCaseId(caseId);
  };

  useEffect(() => {
    const fetchAllCasetrnasactions = async () => {
      try {
        const res = await axios.get(
          "https://case.ir4u.info/api/v1/admin/case/transactions",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
            },
          }
        );
        if (res?.status == 200) {
          // console.log("this is the response of all cases transaction", res.data);
          setAllCaseTransactions(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllCasetrnasactions();
  }, []);

  const handleDeleteDraftCase = async (caseId) => {
    try {
      const response = await axios.delete(
        `https://case.ir4u.info/api/v1/admin/case/delete/draft/${caseId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtTokenApi1")}`,
          },
        }
      );
      if (response.status === 200) {
        Swal.fire("Success", "Case has been deleted", "success");
        setAlldraftCases((prevCases) =>
          prevCases.filter((caseObj) => caseObj.id !== caseId)
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <ModalContext.Provider
      value={{
        setFile,
        setPrice,
        setDateAndTime,
        setTitle,
        setDescription,
        setThumbnail,
        price,
        dateAndTime,
        title,
        description,
        thumbnail,
        inputField,
        handleSubmit,
        handleDeleteWebinar,
        handleUpdateWebinar,
        modalOpen,
        closeModal,
        openModal,
        handleFileChange,
        handleCancel,
        isEditorOpen,
        selectedImage,
        setIsEditorOpen,
        file,
        setModalOpen,
        deleteModal,
        setDeleteModal,
        webinarData,
        isLoading,
        handleJoinMeeting,
        meetingId,
        handleUserJoinMeeting,
        meetingManager,
        videoTiles,
        setVideoTiles,
        leaveMeeting,
        handleUserJoinMeeting,
        copyText,
        setCopyText,
        handleJoinMeetingUrl,
        webinarTitle,
        urlMeetingId,
        setUsername,
        setRole,
        username,
        role,
        handleCopyPresenterUrl,
        handleJoinPresenterUrl,
        passCode,
        setPassCode,
        handleRegisterUserMeeting,
        handleJoinUserUrl,
        transacationDetails,
        bookNowModal,
        setBookNowModal,
        setUpdateUserModal,
        updateUserModal,
        successfulWebinarModal,
        setSuccessfulWebinarModal,
        userId,
        handleCancelWebinar,
        setActiveAltTitle,
        activeAltTitle,
        userList,
        handleDeleteUser,
        handleVerifyEmail,
        sendVerifyEmailLink,
        runUseEffect,
        setRunUseEffect,
        // handleBuyNowClick,
        handlePaymentCheckout,
        isPaymentLoading,
        isbookLoading,
        isJoin,
        isPresenterjoin,
        activeTab,
        setActiveTab,
        handleCreateCourse,
        createCourseOpen,
        handleSaveAndContinue,
        currentPage,
        setCurrentPage,
        handleCreateCase,
        createCaseOpen,
        caseCurrentPage,
        setCaseCurrentPage,
        handlePreviewCasePage,
        courses,
        adminTransactionData,
        deleteCourseFunction,
        userTransactionData,
        activeTabCourse,
        setActiveTabCourse,
        deletedCourses,
        setDeletedCourses,
        restoreCourseFunction,
        selectedCourseIds,
        setSelectedCourseIds,
        handleDeleteMultipleCourses,
        handleCreatecourspage,
        isUpdateCourse,
        courseUpdateId,
        courseTransactionDetails,
        allCourseTransaction,
        activeCaseTab,
        setActiveTabCase,
        cases,
        deleteCaseFunction,
        deletedCases,
        restoreCaseFunction,
        isUpdateCase,
        caseUpdateId,
        allCaseTransaction,
        draftCourseUpdateId,
        setDraftCourseUpdateId,
        isUpdateDraftCaseId,
        updateDraftCaseId,
        allDraftCases,
        handleDeleteDraftCase,
        fetchDraftData,
        isSearchOverlayOpen,
        toggleSearchOverlay,
        messageRefs,
        setMessageRefs,
        searchTerm,
        setSearchTerm,
        highlightedMessageId,
        setHighlightedMessageId,
        chatImageEditURL,
        setChatImageEditURL,
        chatImageEditedResult,
        setChatImageEditedResult,
        isImageEditorOpen,
        setIsImageEditorOpen,
        toggleGroupInfoOverlay,
        isGroupInfoOverlayOpen,
        setIsGroupInfoOverlayOpen,
        toggleAllMediaOverlay,
        setIsSearchOverlayOpen,
        closeAllMediaOverlay,
        isAllMediaOverlayOpen,
        setIsAllMediaOverlayOpen,
        showAllVoters,
        setShowAllVoters,
        handleViewVoters,
        currentPollId,
        setCurrentPollId,
        closeAllVoters,
        isScrollToBottom,
        setIsScrollToBottom,
        activeChatRoomGlobal,
        setActiveChatRoomGloabal,
        isSynopsisVisible,
        setIsSynopsisVisible,
        synopsisData,
        setSynopsisData,
        isViewMoreMenuOpen,
        setIsViewMoreMenuOpen,
        isSearchActive,
        setIsSearchActive,
        userData,
        setUserData,
        isArchived,
        setIsArchived,
        activeRoomsData,
        setActiveRoomsData,
        handleSidebar,
        chatSidebarOpen,
        chatSidebarOpt,
        setChatSidebarOpt,
        handleToggleChatSideBarOpt,
        chatSearchActive,
        setChatSearchActive,
        handleChatSearch,
        chatProfileOpen,
        setChatProfileOpen,
        handleChatProfileOpen,
        handleGroupInfoClick,
        adminPanelType,
        setAdminPanelType,
        handleSelectPanel,
        handleLogout,
        createWebinar,
        setCreateWebinar,
        handleWebinarCreateModalOpen,
        setCreateCaseOpen,
        userDetail,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined)
    throw new Error("Context used outside of the provider");
  return context;
};

export { ModalProvider, useModalContext };
