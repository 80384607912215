import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const PreviewTabComponent = ({ isActive, cards }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className={`ir-ws-flex ir-ws-hme-prvw-panel-container ${isActive}`}>
      {isMobile ? (
        <Slider {...sliderSettings}>
          {cards.map((card, index) => (
            <div key={index} className="ir-ws-hme-prvw-card-container">
              <div className="ir-ws-card-main-container ir-ws-card-cont-mb">
                <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header ir-ws-card-header-bg-overlay">
                  <img
                    className="ir-ws-prvw-card-thumbnail-container"
                    src={card.image}
                    alt="Courses thumbnail"
                  />
                  <div
                    className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                    onClick={card.onClick}
                  >
                    <img
                      src={require("../../assets/images/play-icon.png")}
                      alt="play icon"
                      className="ir-ws-prvw-card-play-icon"
                    />
                  </div>
                </div>
                <div className="ir-ws-card-body">
                  <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                    {card.title}
                  </h4>
                  <ul className="ir-ws-card-body-list-container">
                    {card.points.map((point, idx) => (
                      <li
                        key={idx}
                        className="ir-default-color ir-ws-card-list-item"
                      >
                        {point}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <>
          {cards.map((card, index) => (
            <div
              key={index}
              className="ir-ws-hme-prvw-card-container ir-ws-width-33"
            >
              <div className="ir-ws-card-main-container">
                <div className="ir-ws-text-center ir-ws-position-relative ir-ws-card-header ir-ws-card-header-bg-overlay">
                  <img
                    className="ir-ws-prvw-card-thumbnail-container"
                    src={card.image}
                    alt="Courses thumbnail"
                  />
                  <div
                    className="ir-ws-position-absolute ir-ws-prvw-card-play-icon-container"
                    onClick={card.onClick}
                  >
                    <img
                      src={require("../../assets/images/play-icon.png")}
                      alt="play icon"
                      className="ir-ws-prvw-card-play-icon"
                    />
                  </div>
                </div>
                <div className="ir-ws-card-body">
                  <h4 className="ir-ws-position-relative ir-ws-heading-default-color ir-ws-card-body-title">
                    {card.title}
                  </h4>
                  <ul className="ir-ws-card-body-list-container">
                    {card.points.map((point, idx) => (
                      <li
                        key={idx}
                        className="ir-default-color ir-ws-card-list-item"
                      >
                        {point}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
