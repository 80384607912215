import React, { useEffect, useRef, useState } from "react";
import FindAbnormalityQuestion from "../TypesOfQuestion/FindAbnormalityQuestion";
import RapidQuestion from "../TypesOfQuestion/RapidQuestion";
import FillInTheBlankQuestion from "../TypesOfQuestion/FillInTheBlankQuestion";
import MultipleChoiceQuestion from "../TypesOfQuestion/MultipleChoiceQuestion";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { v4 as uuidv4 } from "uuid";
import JumblingQuestion from "../TypesOfQuestion/JumblingQuestion";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../Courses/AdminCreateCourseTab/ConfirmationModal";
import Swal from "sweetalert2";
import BulkImageCrop from "./BulkImageCrop";
import DiscussionImageCrop from "./DiscussionImageCrop ";

const CreateSlide = ({
  slideNumber,
  onDelete,
  onDataSave,
  slideData,
  activeSlideIndex,
  requiredData,
}) => {
  const slides = useSelector((state) => state.case.slides);

  const targetSlide = slides.find((slide) => slide.slideNumber === slideNumber);

  const [activeDropdown, setActiveDropdown] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addedComponents, setAddedComponents] = useState([]);
  const dropdownRef = useRef(null);
  const [componentData, setComponentData] = useState([]);
  const [fillintheBlanckData, setfillintheBlanckData] = useState([]);
  const [rapidQuestions, setRapidQuestions] = useState([]);
  const [jumblingQuestionData, setJumblingQuestionData] = useState(
    targetSlide?.jumblingQuestionData || []
  );
  const [findAbnormanlity, setFindAbnormanlity] = useState([]);
  const [selectedField, setSelectedField] = useState("");
  const [isMediaUploaded, setIsMediaUploaded] = useState(false);

  const [discussionTitle, setDiscussionTitle] = useState("");
  const [discussionDescription, setDiscussionDescription] = useState("");
  const [images, setImages] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [sequenceImage, setSequenceImage] = useState([]);
  const [componentName, setComponentName] = useState([]);
  const [discussionImage, setDiscussionImage] = useState([]);
  const [showDiscussion, setShowDiscussion] = useState(false);

  // const [discussionImages, setDiscussionImages] = useState([]);
  const [discussionCropImage, setDiscussionCropImage] = useState(null);
  const [discussionCropImageModal, setDiscussionCropImageModal] =
    useState(false);

  const debounceTimer = useRef(null);
  // console.log("Component name array", componentName);
  //Multi Image Crop start////

  const [cropImage, setCropImage] = useState(null);
  const [cropImageModal, setCropImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState([]);
  ///Multi Crop Image State end////

  const sendDataToParent = () => {
    const dataToSend = {
      // categorie: selectedCategory,
      name,
      findAbnormanlity: findAbnormanlity,
      description,
      mcq: componentData,
      fillInTheBlankData: fillintheBlanckData,
      rapidQuestionData: rapidQuestions,
      jumblingQuestionData: jumblingQuestionData,
      discussionTitle,
      discussionDescription,
      discussionImage: discussionImage,
      sequenceImage: sequenceImage,
      caseImages: images,
      caseVideo: selectedVideo,
      componentName: componentName,
    };
    onDataSave(dataToSend);
  };
  useEffect(() => {
    if (
      // selectedCategory !== null ||
      name !== "" ||
      findAbnormanlity.length > 0 ||
      description !== "" ||
      componentData.length > 0 ||
      fillintheBlanckData.length > 0 ||
      rapidQuestions.length > 0 ||
      jumblingQuestionData.length > 0 ||
      discussionTitle !== "" ||
      discussionDescription !== "" ||
      discussionImage.length > 0 ||
      sequenceImage.length > 0 ||
      images.length > 0 ||
      selectedVideo !== null ||
      componentName.length > 0
    ) {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(() => {
        sendDataToParent();
      }, 1000);
    }
  }, [
    name,
    description,
    discussionTitle,
    discussionDescription,
    images,
    selectedVideo,
    sequenceImage,
    componentName,
    discussionImage,
    showDiscussion,
    activeDropdown,
    selectedQuestion,
    // activeCategoryDropdown,
    // selectedCategory,
    showDeleteModal,
    addedComponents,
    componentData,
    fillintheBlanckData,
    rapidQuestions,
    jumblingQuestionData,
    findAbnormanlity,
  ]);

  // console.log(targetSlide);
  // useEffect(() => {
  //   if (requiredData) {
  //     document.getElementById(`caseDescription${slideNumber}`).style.border =
  //       requiredData ? "1px solid #e03131" : "none";
  //   }
  // }, [requiredData]);
  // useEffect(() => {
  //   if (requiredData) {
  //     document.getElementsByClassName("ql-container ql-snow").style.border =
  //       requiredData ? "1px solid #e03131" : "none";
  //   }
  // }, [requiredData]);
  // useEffect(() => {
  //   // Update Quill editor's class based on description
  //   const quillContainer = document.querySelector(".ql-container.ql-snow");
  //   if (quillContainer) {
  //     if (requiredData) {
  //       quillContainer.classList.add("ir-ws-mandatory-filed-border");
  //     } else {
  //       quillContainer.classList.remove("ir-ws-mandatory-filed-border");
  //     }
  //   }
  // }, [requiredData]);
  useEffect(() => {
    const quillContainer = document.querySelector(
      `#caseDescription${slideNumber} .ql-container.ql-snow`
    );
    if (quillContainer) {
      if (requiredData) {
        quillContainer.classList.add("ir-ws-mandatory-filed-border");
      } else {
        quillContainer.classList.remove("ir-ws-mandatory-filed-border");
      }
    }

    const discussionQuillContainer = document.querySelector(
      `#discussionCaseDescription${slideNumber} .ql-container.ql-snow`
    );
    if (discussionQuillContainer) {
      if (requiredData) {
        discussionQuillContainer.classList.add("ir-ws-mandatory-filed-border");
      } else {
        discussionQuillContainer.classList.remove(
          "ir-ws-mandatory-filed-border"
        );
      }
    }
  }, [requiredData, slideNumber]);
  useEffect(() => {
    if (targetSlide) {
      if (targetSlide.name) {
        setName(targetSlide.name);
      }
      if (targetSlide.description) {
        setDescription(targetSlide.description);
      }
      if (targetSlide.dicussionTitle) {
        handleShowDiscussion();
        setDiscussionTitle(targetSlide.dicussionTitle);
      }
      if (targetSlide.discussionDescription) {
        handleShowDiscussion();
        setDiscussionDescription(targetSlide.discussionDescription);
      }
      // if (targetSlide.categorie) {
      //   setSelectedCategory(targetSlide.categorie);
      // }
      if (targetSlide.caseImages && targetSlide.caseImages.length !== 0) {
        setImages(targetSlide.caseImages);
      }
      if (targetSlide.caseVideo && targetSlide.caseVideo !== "") {
        setSelectedVideo(targetSlide.caseVideo);
      }
      if (
        targetSlide.discussionImage &&
        targetSlide.discussionImage.length !== 0
      ) {
        handleShowDiscussion();
        setDiscussionImage(targetSlide.discussionImage);
      }

      // if (targetSlide.componentName && targetSlide.componentName.length !== 0) {
      //   // Filter out "Rapid" component name if rapidQuestionData is empty
      //   const filteredComponentName = targetSlide.componentName.filter(
      //     (component) => {
      //       if (component.typesOfQuestion === "Rapid") {
      //         // Check if corresponding rapidQuestionData is empty
      //         const rapidQuestionData = targetSlide.rapidQuestionData.find(
      //           (data) => data.id === component.id
      //         );
      //         if (rapidQuestionData) {
      //           console.log(
      //             `Found rapidQuestionData for component id ${component.id}:`,
      //             rapidQuestionData
      //           );
      //         } else {
      //           console.log(
      //             `No rapidQuestionData found for component id ${component.id}`
      //           );
      //         }
      //         return rapidQuestionData && rapidQuestionData.data.length !== 0;
      //       } else {
      //         return true;
      //       }
      //     }
      //   );
      //   console.log("Filtered componentName:", filteredComponentName);
      //   setComponentName(filteredComponentName);
      // }

      if (targetSlide.componentName && targetSlide.componentName.length !== 0) {
        setComponentName(targetSlide.componentName);
      }

      if (
        targetSlide.fillInTheBlankData &&
        targetSlide.fillInTheBlankData.length !== 0
      ) {
        setfillintheBlanckData(targetSlide.fillInTheBlankData);
      }
      if (targetSlide.mcq && targetSlide.mcq.length !== 0) {
        setComponentData(targetSlide.mcq);
      }
      if (
        targetSlide.findAbnormanlity &&
        targetSlide.findAbnormanlity.length !== 0
      ) {
        // setComponentData(targetSlide.mcq);
        setFindAbnormanlity(targetSlide.findAbnormanlity);
      }
      // if (
      //   targetSlide.jumblingQuestionData &&
      //   targetSlide.jumblingQuestionData.length !== 0
      // ) {
      //   // setJumblingQuestionData(targetSlide.jumblingQuestionData);
      // }
      if (
        targetSlide.rapidQuestionData &&
        targetSlide.rapidQuestionData.length !== 0
      ) {
        setRapidQuestions(targetSlide.rapidQuestionData);
      }
      if (targetSlide.sequenceImage && targetSlide.sequenceImage.length !== 0) {
        setSequenceImage(targetSlide.sequenceImage);
      }
    }
  }, [targetSlide]);

  const handleShowDiscussion = () => {
    setShowDiscussion(true);
    // sendDataToParent();
  };

  const deleteShowDiscussion = () => {
    setDiscussionTitle("");
    setDiscussionDescription("");
    setDiscussionImage([]);
    setShowDiscussion(false);
  };
  const handleNameChange = (value) => {
    setName(value);
    // sendDataToParent();
  };
  const handleDiscussionTitle = (value) => {
    setDiscussionTitle(value);
    // sendDataToParent();
  };
  const handleDiscussionDescription = (value) => {
    setDiscussionDescription(value);
    // sendDataToParent();
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
    // sendDataToParent();
  };
  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };
  const handleDeleteComponent = (id) => {
    setAddedComponents((prevComponents) => {
      return prevComponents.filter((component) => component.props.id !== id);
    });
    // setComponentName((prevNames) =>
    //   prevNames.filter((name) => name !== selectedQuestion)
    // );
    setComponentName((prevNames) => prevNames.filter((name) => name.id !== id));
    if (
      targetSlide.fillInTheBlankData &&
      targetSlide.fillInTheBlankData.length !== 0
    ) {
      setfillintheBlanckData((prevData) =>
        prevData.filter((data) => data.id !== id)
      );
    }

    if (targetSlide.mcq && targetSlide.mcq.length !== 0) {
      setComponentData((prevData) => prevData.filter((data) => data.id !== id));
    }
    if (
      targetSlide.findAbnormanlity &&
      targetSlide.findAbnormanlity.length !== 0
    ) {
      // setComponentData(targetSlide.mcq);
      setFindAbnormanlity((prevData) =>
        prevData.filter((data) => data.id !== id)
      );
    }

    if (
      targetSlide.jumblingQuestionData &&
      targetSlide.jumblingQuestionData.length !== 0
    ) {
      setJumblingQuestionData((prevData) =>
        prevData.filter((data) => data.id !== id)
      );
    }
    if (
      targetSlide.rapidQuestionData &&
      targetSlide.rapidQuestionData.length !== 0
    ) {
      setRapidQuestions((prevData) =>
        prevData.filter((data) => data.id !== id)
      );
    }
  };

  const handleMCQData = (data, componentId) => {
    setComponentData((prevComponentData) => {
      const componentIndex = prevComponentData?.findIndex(
        (component) => component.id === componentId
      );
      if (componentIndex !== -1) {
        return prevComponentData?.map((component, index) =>
          index === componentIndex ? { id: componentId, data } : component
        );
      } else {
        return [...prevComponentData, { id: componentId, data }];
      }
    });
    // sendDataToParent();
  };

  const handleFillInTheBlankDataChange = (data, componentId) => {
    const componentIndex = fillintheBlanckData?.findIndex(
      (component) => component.id === componentId
    );
    if (componentIndex !== -1) {
      const updatedfillintheBlanckData = [...fillintheBlanckData];
      updatedfillintheBlanckData[componentIndex] = { id: componentId, data };
      setfillintheBlanckData(updatedfillintheBlanckData);
    } else {
      setfillintheBlanckData([
        ...fillintheBlanckData,
        { id: componentId, data },
      ]);
    }
    // sendDataToParent();
  };

  const handleRapidQuestionDataChange = (data, componentId) => {
    const componentIndex = rapidQuestions.findIndex(
      (component) => component.id === componentId
    );
    if (componentIndex !== -1) {
      const updatedRapidQuestions = [...rapidQuestions];
      updatedRapidQuestions[componentIndex] = { id: componentId, data };
      setRapidQuestions(updatedRapidQuestions);
    } else {
      setRapidQuestions([...rapidQuestions, { id: componentId, data }]);
    }
    // sendDataToParent();
  };

  const handleJumblingQuestionDataChange = (data, componentId) => {
    const componentIndex = jumblingQuestionData.findIndex(
      (component) => component.id === componentId
    );
    if (componentIndex !== -1) {
      const updatedjumblingQuestionData = [...jumblingQuestionData];
      updatedjumblingQuestionData[componentIndex] = { id: componentId, data };
      setJumblingQuestionData(updatedjumblingQuestionData);
    } else {
      setJumblingQuestionData([
        ...jumblingQuestionData,
        { id: componentId, data },
      ]);
    }
    // sendDataToParent();
  };

  const handleFindAbnormalityData = (data, componentId) => {
    // console.log("Data from child:", data);
    setFindAbnormanlity((prevFindAbnormality) => {
      const componentIndex = prevFindAbnormality.findIndex(
        (component) => component.id === componentId
      );
      if (componentIndex !== -1) {
        return prevFindAbnormality.map((component, index) =>
          index === componentIndex ? { id: componentId, data } : component
        );
      } else {
        return [...prevFindAbnormality, { id: componentId, data }];
      }
    });
  };

  // console.log(targetSlide);

  useEffect(() => {
    if (targetSlide?.componentName?.length > 0) {
      const newComponents = targetSlide?.componentName?.map((questionType) => {
        // const id = uuidv4();
        switch (questionType.typesOfQuestion) {
          case "Multiple Choice":
            const mcqData = targetSlide?.mcq?.find(
              (data) => data.id === questionType.id
            );
            return (
              <MultipleChoiceQuestion
                key={questionType.id}
                id={questionType.id}
                onDelete={handleDeleteComponent}
                onQuestionDataChange={(data) =>
                  handleMCQData(data, questionType.id)
                }
                question={mcqData?.data.question}
                answers={mcqData?.data.answers}
                correctAnswer={mcqData?.data.correctAnswer}
              />
            );
          case "Fill in the Blank":
            const fillInTheBlankData = targetSlide?.fillInTheBlankData?.find(
              (data) => data.id === questionType.id
            );
            return (
              <FillInTheBlankQuestion
                key={questionType.id}
                id={questionType.id}
                onDelete={handleDeleteComponent}
                onQuestionDataChange={(data) =>
                  handleFillInTheBlankDataChange(data, questionType.id)
                }
                slideNumber={slideNumber}
                question={fillInTheBlankData?.data.question}
                correctAnswer={fillInTheBlankData?.data.correctAnswer}
              />
            );
          case "Find Abnormality":
            const findAbnormalityData = targetSlide?.findAbnormanlity?.find(
              (data) => data.id === questionType.id
            );
            return (
              <FindAbnormalityQuestion
                key={questionType.id}
                id={questionType.id}
                onDelete={handleDeleteComponent}
                onFindAbnormalityData={(data) =>
                  handleFindAbnormalityData(data, questionType.id)
                }
                slideNumber={slideNumber}
                question={findAbnormalityData?.data.data}
                point={findAbnormalityData?.data.point}
                image={findAbnormalityData?.data.image}
                video={findAbnormalityData?.data.video}
              />
            );
          case "Rapid":
            const rapidQuestionData = targetSlide?.rapidQuestionData?.find(
              (data) => data.id === questionType.id
            );
            return (
              <RapidQuestion
                key={questionType.id}
                delId={questionType.id}
                onDelete={handleDeleteComponent}
                onQuestionDataChange={(data) =>
                  handleRapidQuestionDataChange(data, questionType.id)
                }
                rapidQuestionData={rapidQuestionData}
                slideNumber={slideNumber}
                targetSlide={targetSlide}
                setRapidQuestions={setRapidQuestions}
                rapidTimeData={targetSlide.rapidTime}
              />
            );
          case "Jumbling":
            const jumblingQuestionData =
              targetSlide?.jumblingQuestionData?.find(
                (data) => data.id === questionType.id
              );
            return (
              <JumblingQuestion
                key={questionType.id}
                id={questionType.id}
                onDelete={handleDeleteComponent}
                onQuestionDataChange={(data) =>
                  handleJumblingQuestionDataChange(data, questionType.id)
                }
                jumblinQuestion={jumblingQuestionData?.data[0]?.question}
                jumblinQuestionSets={jumblingQuestionData?.data}
              />
            );
          default:
            return null;
        }
      });

      setAddedComponents(newComponents);
    }
  }, []);

  const handleAddButtonClick = () => {
    if (selectedQuestion) {
      const id = uuidv4();
      let newComponent;
      switch (selectedQuestion) {
        case "Multiple Choice":
          newComponent = (
            <MultipleChoiceQuestion
              key={id}
              id={id}
              onDelete={handleDeleteComponent}
              onQuestionDataChange={(data) => handleMCQData(data, id)}
            />
          );
          break;
        case "Fill in the Blank":
          newComponent = (
            <FillInTheBlankQuestion
              key={id}
              id={id}
              onDelete={handleDeleteComponent}
              onQuestionDataChange={(data) =>
                handleFillInTheBlankDataChange(data, id)
              }
            />
          );
          break;
        case "Find Abnormality":
          newComponent = (
            <FindAbnormalityQuestion
              key={id}
              id={id}
              onDelete={handleDeleteComponent}
              onFindAbnormalityData={(data) =>
                handleFindAbnormalityData(data, id)
              }
              slideNumber={slideNumber}
            />
          );
          break;
        case "Rapid":
          newComponent = (
            <RapidQuestion
              key={id}
              id={id}
              onDelete={handleDeleteComponent}
              onQuestionDataChange={(data) =>
                handleRapidQuestionDataChange(data, id)
              }
              setRapidQuestions={setRapidQuestions}
              slideNumber={slideNumber}
            />
          );
          break;
        case "Jumbling":
          newComponent = (
            <JumblingQuestion
              key={id}
              id={id}
              onDelete={handleDeleteComponent}
              onQuestionDataChange={(data) =>
                handleJumblingQuestionDataChange(data, id)
              }
            />
          );
          break;
        default:
          break;
      }
      setAddedComponents((prevComponents) => [...prevComponents, newComponent]);
      // setComponentName((prevNames) => [...prevNames, selectedQuestion]);
      setComponentName((prevNames) => [
        ...prevNames,
        { id: id, typesOfQuestion: selectedQuestion },
      ]);
    }
  };

  const toggleDropdown = () => {
    setActiveDropdown(!activeDropdown);
    sendDataToParent();
  };

  useEffect(() => {
    if (selectedQuestion) {
      handleAddButtonClick();
      setSelectedQuestion(null);
    }
  }, [selectedQuestion]);

  const handleQuestionSelection = (question) => {
    setSelectedQuestion(question);
    setActiveDropdown(false);
  };

  const modules = {
    toolbar: [["bold", "italic"]],
  };
  const discussionModules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
    ],
  };
  useEffect(() => {
    if (activeDropdown) {
      // If dropdown is active, set the height to the actual height of the content
      // dropdownRef.current.style.height = `${dropdownRef.current.scrollHeight}px`;
      dropdownRef.current.style.height = "200px";
    } else {
      // If dropdown is inactive, reset the height to 0
      dropdownRef.current.style.height = "0";
    }
  }, [activeDropdown]);

  const handleSelectedCropImage = (event) => {
    console.log("isMediaUploaded state:", isMediaUploaded);
    if (selectedField !== "" && selectedField !== "singleImage") {
      // if (isMediaUploaded) {
      Swal.fire({
        title: "Note",
        text: "You can only select one type of media",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    } else {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const imageUrl = URL.createObjectURL(selectedFile);
        setSelectedImage(selectedFile);
        setCropImage(imageUrl);
        setCropImageModal(true);
        setSelectedField("singleImage");
        setIsMediaUploaded(true);
      }
    }
  };

  const handleImageDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile && droppedFile.type.startsWith("image/")) {
      handleSelectedCropImage({ target: { files: [droppedFile] } });
    } else {
      alert("Please drop a valid image file.");
    }
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  // const handleCloseImageCrop = () => {
  //   setCropImageModal(false);
  // };

  // const handleDiscussionImageChange = (event) => {
  //   const files = event.target.files;
  //   const newImages = [...discussionImage];

  //   for (let i = 0; i < files.length; i++) {
  //     newImages.push({
  //       name: files[i].name,
  //       url: URL.createObjectURL(files[i]),
  //     });
  //   }

  //   setDiscussionImage(newImages);
  //   // sendDataToParent();
  // };

  const handleDeleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    // sendDataToParent();
    // if (newImages.length == 0) {
    setSelectedField("");
    setIsMediaUploaded(false);
    // }
    // Reset the file input value
    const fileInput = document.getElementById(`inputSequence${slideNumber}`);
    if (fileInput) {
      fileInput.value = "";
    }

    // console.log("images in upload:", images);
  };

  const handleSequenceImageDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (!droppedFiles.length) return;

    const validFiles = [...droppedFiles].filter((file) =>
      file.type.startsWith("image/")
    );

    if (validFiles.length === 0) {
      alert("Please drop valid image files.");
      return;
    }

    handleSequenceImageChange({ target: { files: validFiles } });
  };

  const handleSequenceImageChange = (event) => {
    console.log("isMediaUploaded state:", isMediaUploaded);
    // if (isMediaUploaded) {
    if (selectedField !== "" && selectedField !== "multipleImages") {
      Swal.fire({
        title: "Note",
        text: "You can only select one type of media",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    } else {
      const files = event.target.files;
      const newImages = [...sequenceImage];

      for (let i = 0; i < files.length; i++) {
        newImages.push({
          name: files[i].name,
          url: URL.createObjectURL(files[i]),
        });
      }

      setSequenceImage(newImages);
      setSelectedField("multipleImages");
      setIsMediaUploaded(true);
    }
    // sendDataToParent();
  };

  const handleCloseImageCrop = () => {
    setSelectedImage([]);
    setCropImage("");
    // setImageThumbnail("");
    setCropImageModal(false);
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  // const handleDeleteSequenceImage = (index) => {
  //   const newImages = [...sequenceImage];
  //   newImages.splice(index, 1);
  //   setSequenceImage(newImages);
  // };
  const handleDeleteSequenceImage = () => {
    // Clear the array by setting it to an empty array
    setSequenceImage([]);
    setSelectedField("");
    setIsMediaUploaded(false);

    const fileInput = document.getElementById(`sequenceImage${slideNumber}`);
    if (fileInput) {
      fileInput.value = "";
    }
  };
  const handleVideoDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile && droppedFile.type.startsWith("video/")) {
      handleSelectedVideo({ target: { files: [droppedFile] } });
    } else {
      alert("Please drop a valid video file.");
    }
  };

  const handleSelectedVideo = (event) => {
    console.log("isMediaUploaded state:", isMediaUploaded);
    // if (isMediaUploaded) {
    if (selectedField !== "" && selectedField !== "video") {
      Swal.fire({
        title: "Note",
        text: "You can only select one type of media",
        icon: "warning",
        confirmButtonText: "OK",
      });
      return;
    } else {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const videoUrl = URL.createObjectURL(selectedFile);
        setSelectedVideo(videoUrl);
      }
      // sendDataToParent();
      setSelectedField("video");
      setIsMediaUploaded(true);
    }
  };
  const handleDeleteSelectedVideo = () => {
    setSelectedVideo(null);
    // sendDataToParent();
    setSelectedField("");
    setIsMediaUploaded(false);

    const fileInput = document.getElementById(`videoCase${slideNumber}`);
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleDiscussionImageDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile && droppedFile.type.startsWith("image/")) {
      handleDiscussionImageChange({ target: { files: [droppedFile] } });
    } else {
      alert("Please drop a valid image file.");
    }
  };
  const handleDiscussionImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setDiscussionCropImage(imageUrl);
      setDiscussionCropImageModal(true);
    }
  };

  const handleCroppedDiscussionImageSave = (newImage) => {
    setDiscussionImage((prevImages) => [...prevImages, newImage]);
  };

  const handleDeleteDiscussionImage = (index) => {
    const newImages = [...discussionImage];
    newImages.splice(index, 1);
    setDiscussionImage(newImages);
    // sendDataToParent();
  };

  useEffect(() => {
    console.log("isMediaUploaded state:", isMediaUploaded);
  }, [isMediaUploaded]);

  return (
    <div className="ir-ws-create-case-slide-container">
      <div className="ir-ws-create-case-question-text-box">
        <p className="ir-ws-create-case-question-text">Slide - {slideNumber}</p>
        <div>
          <svg
            height="30"
            viewBox="0 0 48 48"
            width="30"
            xmlns="http://www.w3.org/2000/svg"
            className="ir-ws-creat-case-icon "
            onClick={toggleDeleteModal}
          >
            <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
            <path d="M0 0h48v48H0z" fill="none" />
          </svg>
        </div>
      </div>
      <div className="ir-ws-create-case-all-questions-container">
        <div className="ir-ws-create-case-all-questions-box">
          <p className="ir-ws-create-case-title-text">
            Case Name<span className="ir-case-mandatory-field-icon">*</span>
          </p>
          <div className="ir-ws-multiple-choice-question-container">
            <div className="ir-ws-multiple-choice-question-box">
              {/* <ReactQuill
                placeholder="Name"
                theme="snow"
                modules={modules}
                onChange={(value) => handleNameChange(value)}
              /> */}
              <input
                // className="ir-ws-create-case-input"
                className={`${
                  requiredData ? "ir-ws-mandatory-filed-border" : ""
                } ir-ws-create-case-input`}
                placeholder="Case Name"
                value={name}
                onChange={(event) => handleNameChange(event.target.value)}
                // onChange={(e) => {
                //   setName(e.target.value);
                // }}
              />
            </div>
          </div>
          <p className="ir-ws-create-case-title-text">Case Description</p>
          <div className="ir-ws-multiple-choice-question-container">
            <div className="ir-ws-multiple-choice-question-box ir-ws-create-case-description-container">
              <ReactQuill
                placeholder="Case Description"
                theme="snow"
                modules={modules}
                value={description}
                onChange={(value) => handleDescriptionChange(value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="ir-ws-create-case-all-questions-container">
        <div className="ir-ws-create-case-all-questions-box">
          <p className="ir-ws-create-case-title-text">Upload Media</p>
          {images?.length !== 0 && (
            <div>
              <p className="ir-ws-create-case-title-text">Selected Image</p>
              <div className="ir-ws-create-case-bulk-image-container">
                {images?.map((image, index) => (
                  <div key={index} className="ir-ws-create-case-bulk-image-box">
                    <img src={image?.url} alt={image?.name} />
                    {/* <p
                      title={image?.name}
                      className="ir-ws-create-case-bulk-image-name"
                    >
                      {image?.name.length > 10
                        ? `${image?.name.substring(0, 10)}...`
                        : image?.name}
                    </p> */}

                    <div
                      className="ir-ws-create-case-delete-bulk-image"
                      onClick={() => handleDeleteImage(index)}
                    >
                      <p>x</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {selectedVideo && (
            <div
              className="ir-ws-rapid-question-time-add-btn-container "
              style={{ border: "none" }}
            >
              <p className="ir-ws-create-case-title-text">Selected video</p>
              <div className="ir-ws-create-case-selected-image">
                <video
                  controls
                  className="ir-ws-create-case-selected-video-slide"
                >
                  <source src={selectedVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <svg
                  height="30"
                  viewBox="0 0 48 48"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ir-ws-creat-case-delete-selected-image"
                  onClick={handleDeleteSelectedVideo}
                >
                  <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
                  <path d="M0 0h48v48H0z" fill="none" />
                </svg>
              </div>
            </div>
          )}
          {sequenceImage && sequenceImage.length !== 0 && (
            <div>
              <p className="ir-ws-create-case-title-text">
                Selected sequence Image
              </p>

              <div className="ir-ws-create-case-sequence-image-container">
                <div className="ir-ws-create-case-sequence-image-border-one"></div>
                <div className="ir-ws-create-case-sequence-image-border-two"></div>
                <div className="ir-ws-create-case-sequence-image-border-three">
                  <img
                    src={sequenceImage[0]?.url}
                    alt={sequenceImage[0]?.name}
                    className="ir-ws-create-case-sequence-image"
                  />
                </div>
                <div
                  className="ir-ws-create-case-sequence-image-cross-box"
                  onClick={handleDeleteSequenceImage}
                >
                  <p>x</p>
                </div>
              </div>

              {/* </div> */}
            </div>
          )}
          <div className="ir-ws-flex" style={{ marginBottom: "20px" }}>
            <div
              className="ir-ws-create-course-select-image-box"
              onDragOver={allowDrop}
              onDrop={handleImageDrop}
            >
              <label
                htmlFor={`inputSequence${slideNumber}`}
                className="ir-ws-create-case-select-image-label"
              >
                <div class="ir-ws-create-case-select-image-container">
                  <img
                    src={require("../../../assets/images/imgaeicon.png")}
                    alt="icon"
                    className="ir-ws-create-case-image-icon"
                  />
                  <input
                    type="file"
                    id={`inputSequence${slideNumber}`}
                    accept="image/*"
                    // disabled={
                    //   selectedField !== "" && selectedField !== "singleImage"
                    // }
                    // multiple
                    // onChange={handleImageChange}
                    onChange={handleSelectedCropImage}
                    className="ir-ws-create-case-image"
                  />
                </div>
              </label>
              <div>
                <p className="ir-ws-create-case-upload-image-text">
                  Upload Image
                </p>
              </div>
            </div>
            <div
              className="ir-ws-create-course-select-image-box"
              onDragOver={allowDrop}
              onDrop={handleVideoDrop}
            >
              <label
                htmlFor={`videoCase${slideNumber}`}
                className="ir-ws-create-case-select-image-label"
              >
                <div class="ir-ws-create-case-select-image-container">
                  <svg
                    viewBox="0 0 30 30"
                    xmlns="http://www.w3.org/2000/svg"
                    class="ir-ws-create-case-video-icon"
                  >
                    <g data-name="Layer 2">
                      <path
                        d="M15 2.5A12.5 12.5 0 1 0 27.5 15 12.514 12.514 0 0 0 15 2.5Zm4.968 14.14-5.647 3.942a2 2 0 0 1-3.144-1.64v-7.883a2 2 0 0 1 3.144-1.641l5.647 3.941a2 2 0 0 1 0 3.28Z"
                        fill="none"
                      ></path>
                      <path
                        d="M15 0a15 15 0 1 0 15 15A15.016 15.016 0 0 0 15 0Zm0 27.5A12.5 12.5 0 1 1 27.5 15 12.514 12.514 0 0 1 15 27.5Z"
                        fill="#e0e0e0"
                        class="fill-000000"
                      ></path>
                      <path
                        d="M19.968 13.36 14.32 9.417a2 2 0 0 0-3.144 1.64v7.883a2 2 0 0 0 3.144 1.641l5.647-3.941v-.001a2 2 0 0 0 0-3.28Z"
                        fill="#e0e0e0"
                        class="fill-000000"
                      ></path>
                    </g>
                  </svg>

                  <input
                    type="file"
                    id={`videoCase${slideNumber}`}
                    // disabled={selectedField !== "" && selectedField !== "video"}
                    class="ir-ws-create-case-image"
                    accept="video/*"
                    onChange={handleSelectedVideo}
                  />
                </div>
              </label>
              <div>
                <p className="ir-ws-create-case-upload-image-text">
                  Upload Video
                </p>
              </div>
            </div>
            <div
              className="ir-ws-create-course-select-image-box"
              onDragOver={allowDrop}
              onDrop={handleSequenceImageDrop}
            >
              <label
                htmlFor={`sequenceImage${slideNumber}`}
                className="ir-ws-create-case-select-image-label"
              >
                <div class="ir-ws-create-case-select-image-container">
                  <img
                    src={require("../../../assets/images/imgaeicon.png")}
                    alt="icon"
                    className="ir-ws-create-case-image-icon"
                  />
                  <input
                    type="file"
                    id={`sequenceImage${slideNumber}`}
                    // disabled={
                    //   selectedField !== "" && selectedField !== "multipleImages"
                    // }
                    accept="image/*"
                    multiple
                    onChange={handleSequenceImageChange}
                    className="ir-ws-create-case-image"
                  />
                </div>
              </label>
              <div>
                <p className="ir-ws-create-case-upload-image-text">
                  Image Sequence
                </p>
              </div>
            </div>
          </div>
          {addedComponents?.map((component, index) => (
            <div key={index}>{component}</div>
          ))}
          <p className="ir-ws-create-case-title-text">
            Types of Question{" "}
            <span className="ir-case-mandatory-field-icon">*</span>
          </p>
          <div className="ir-ws-create-case-types-of-question-container">
            <div className="ir-ws-create-case-types-of-question-add-btn-box">
              <div
                className={`${
                  requiredData
                    ? "ir-ws-mandatory-filed-border"
                    : "ir-ws-normal-filled-border"
                } ir-ws-create-case-all-types-question-box`}
                onClick={toggleDropdown}
              >
                <p>{selectedQuestion || "Types of Question"}</p>
                {!activeDropdown ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="ir-ws-create-case-all-types-question-dropdown"
                    onClick={toggleDropdown}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="ir-ws-create-case-all-types-question-dropdown"
                    onClick={toggleDropdown}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m4.5 15.75 7.5-7.5 7.5 7.5"
                    />
                  </svg>
                )}
              </div>
            </div>
            <div
              className="ir-ws-create-case-types-of-question-list"
              ref={dropdownRef}
            >
              <ul className={activeDropdown ? "show" : ""}>
                <li onClick={() => handleQuestionSelection("Multiple Choice")}>
                  Multiple Choice
                </li>
                <li
                  onClick={() => handleQuestionSelection("Fill in the Blank")}
                >
                  Fill in the Blank
                </li>
                <li onClick={() => handleQuestionSelection("Find Abnormality")}>
                  Find Abnormality
                </li>
                <li onClick={() => handleQuestionSelection("Rapid")}>Rapid</li>
                <li onClick={() => handleQuestionSelection("Jumbling")}>
                  Jumbling
                </li>
              </ul>
            </div>
          </div>
          <div className="ir-ws-flex ir-ws-creat-case-discussion-cont">
            <div
              className="ir-ws-text-center ir-ws-default-btn-container"
              onClick={handleShowDiscussion}
            >
              <button
                className="ir-ws-app-bg btn ir-color-white ir-ws-no-border ir-ws-default-btn ir-ws--create-webinar-submit-button"
                disabled={showDiscussion}
              >
                <span> Learning Points</span>
              </button>
            </div>
            {showDiscussion && (
              <div>
                <svg
                  height="30"
                  viewBox="0 0 48 48"
                  width="30"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ir-ws-creat-case-icon "
                  onClick={deleteShowDiscussion}
                >
                  <path d="M12 38c0 2.21 1.79 4 4 4h16c2.21 0 4-1.79 4-4V14H12v24zM38 8h-7l-2-2H19l-2 2h-7v4h28V8z" />
                  <path d="M0 0h48v48H0z" fill="none" />
                </svg>
              </div>
            )}
          </div>
          {showDiscussion && (
            <div>
              <p className="ir-ws-create-case-title-text">
                Learning Point Title{" "}
                <span className="ir-case-mandatory-field-icon">*</span>
              </p>
              <div className="ir-ws-multiple-choice-question-container">
                <div className="ir-ws-multiple-choice-question-box">
                  {/* <ReactQuill
                placeholder="Name"
                theme="snow"
                modules={modules}
                onChange={(value) => handleNameChange(value)}
              /> */}
                  <input
                    className={`${
                      requiredData ? "ir-ws-mandatory-filed-border" : ""
                    } ir-ws-create-case-input`}
                    placeholder="Learning Point Title"
                    value={discussionTitle}
                    onChange={(event) =>
                      handleDiscussionTitle(event.target.value)
                    }
                  />
                </div>
              </div>
              {discussionImage?.length !== 0 && (
                <div>
                  <p className="ir-ws-create-case-title-text">Selected Image</p>
                  <div className="ir-ws-create-case-bulk-image-container">
                    {discussionImage?.map((image, index) => (
                      <div
                        key={index}
                        className="ir-ws-create-case-bulk-image-box"
                      >
                        <img src={image.url} alt={image.name} />
                        <p
                          title={image.name}
                          className="ir-ws-create-case-bulk-image-name"
                        >
                          {image.name.length > 10
                            ? `${image.name.substring(0, 10)}...`
                            : image.name}
                        </p>

                        <div
                          className="ir-ws-create-case-delete-bulk-image"
                          onClick={() => handleDeleteDiscussionImage(index)}
                        >
                          <p>x</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div
                className="ir-ws-create-course-select-image-box"
                onDragOver={allowDrop}
                onDrop={handleDiscussionImageDrop}
              >
                <label
                  htmlFor={`discussIonImage${slideNumber}`}
                  className="ir-ws-create-case-select-image-label"
                >
                  <div class="ir-ws-create-case-select-image-container">
                    <img
                      src={require("../../../assets/images/imgaeicon.png")}
                      alt="icon"
                      className="ir-ws-create-case-image-icon"
                    />
                    <input
                      type="file"
                      id={`discussIonImage${slideNumber}`}
                      accept="image/*"
                      multiple
                      onChange={handleDiscussionImageChange}
                      className="ir-ws-create-case-image"
                    />
                  </div>
                </label>
                <div>
                  <p className="ir-ws-create-case-upload-image-text">
                    Upload Image
                  </p>
                </div>
              </div>

              <p className="ir-ws-create-case-title-text">
                Learning Point Description{" "}
                <span className="ir-case-mandatory-field-icon">*</span>
              </p>
              <div className="ir-ws-multiple-choice-question-container">
                <div className="ir-ws-multiple-choice-question-box ir-ws-create-case-description-container">
                  <ReactQuill
                    placeholder="Learning Point Description"
                    id={`discussionCaseDescription${slideNumber}`}
                    theme="snow"
                    modules={discussionModules}
                    value={discussionDescription}
                    onChange={(value) => handleDiscussionDescription(value)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {cropImageModal && (
        <BulkImageCrop
          cropImage={cropImage}
          // setImageThumbnail={setImageThumbnail}
          setCropImageModal={setCropImageModal}
          handleCloseImageCrop={handleCloseImageCrop}
          setImages={setImages}
        />
      )}

      {discussionCropImageModal && (
        <DiscussionImageCrop
          cropImage={discussionCropImage}
          setCropImageModal={setDiscussionCropImageModal}
          handleCroppedImageSave={handleCroppedDiscussionImageSave}
          handleCloseImageCrop={() => setDiscussionCropImageModal(false)}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          toggleModal={toggleDeleteModal}
          confirmationFunc={() => {
            onDelete();
            toggleDeleteModal();
          }}
          title="cancel"
        />
      )}
    </div>
  );
};

export default CreateSlide;
