import React, { useState } from "react";
import CaseAdminContent from "../../../pages/AdminCases/CaseAdminContent";
import AdminCreateCaseContent from "../../../pages/AdminCases/AdminCreateCaseContent";

const CasesPanel = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="h-[100%]">
      <CaseAdminContent toggle={toggle} setToggle={setToggle} />
      <AdminCreateCaseContent toggle={toggle} setToggle={setToggle} />
    </div>
  );
};

export default CasesPanel;
