import React, { useState } from "react";
import "./WebinarAdminUserListTab.scss";
import DeleteUserModal from "./DeleteUserModal";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";

import {
  addSecondaryAdmin,
  removeSecondaryAdmin,
} from "../../api/secondaryAdminApi";
import AdminConfirmModal from "../Chat/AdminConfirmModal/AdminConfirmModal";

const WebinarAdminUserListTab = ({
  firstname,
  lastname,
  userId,
  email,
  username,
  userInfo,
}) => {
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [isContentVisible, setIsContentVisible] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleOpenModal = () => {
    setDeleteUserModal(true);
  };
  const handlePromoteAdmin = () => {
    console.log("clickeddd on admin");
    setShowConfirmationModal(true);
  };
  const handleCancelPromoteAdmin = () => {
    setShowConfirmationModal(false);
  };
  const toggleContentVisibility = () => {
    setIsContentVisible(!isContentVisible);
  };
  const accordianListButton = ["Email", "Username", "Firstname", "Lastname"];
  const userData = [
    {
      email: email,
      username: username,
      firstname: firstname,
      lastname: lastname,
      userId: userId,
    },
  ];

  // Make a user a secondary admin
  const handleAddAdmin = async () => {
    try {
      const data = await addSecondaryAdmin(userId);
      setShowConfirmationModal(false);
      console.log("User added as secondary admin:", data);
    } catch (error) {
      console.error("Error:", error);
      setShowConfirmationModal(false);
    }
  };

  // Remove a secondary admin
  const handleRemoveAdmin = async () => {
    try {
      const data = await removeSecondaryAdmin(userId);
      console.log("User removed from secondary admin role:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // console.log("Admin userData:", userInfo?.role);

  return (
    <div className={`ir-ws-webinar-list-tab `}>
      <div className="ir-ws-admin-webinar-content">
        <div className="ir-ws-admin-webinar-title-container">
          <p className="ir-ws-webinar-title-text">{username}</p>
        </div>
        <div className="ir-ws-admin-list-button-container">
          <button
            className="ir-ws-webinar-actions-button"
            // onClick={() => handleAddAdmin(userId)}
            onClick={handlePromoteAdmin}
          >
            Promote
          </button>

          <button
            className="ir-ws-webinar-actions-button"
            onClick={handleOpenModal}
          >
            Delete User
          </button>
          <button
            className={`ir-ws-webinar-transaction-button ir-ws-webinar-actions-button flex items-center gap-[4px] ${
              isContentVisible ? "ir-ws-webinar-transaction-button-active" : ""
            }`}
            onClick={toggleContentVisibility}
          >
            User Details
            <i class="bi bi-chevron-down"></i>
          </button>
        </div>
      </div>
      {isContentVisible && (
        <div>
          <ul className="ir-ws-webinar-list-accordian-tab ir-ws-webinar-admin-user-list">
            {accordianListButton.map((item, index) => (
              <li className="ir-ws-list-item-box" key={index}>
                {item}
              </li>
            ))}
          </ul>
          <div className="ir-ws-webinar-admin-userlist-detail">
            {userData.map((item, index) => (
              <ul className="ir-ws-webinar-list-data " key={index}>
                <li>{item.email}</li>
                <li>{item.username}</li>
                <li>{item.firstname}</li>
                <li>{item.lastname}</li>
              </ul>
            ))}
          </div>
        </div>
      )}
      {deleteUserModal && (
        <DeleteUserModal
          setDeleteUserModal={setDeleteUserModal}
          userId={userId}
          username={username}
        />
      )}

      {showConfirmationModal && (
        <AdminConfirmModal
          onConfirm={handleAddAdmin}
          onCancel={handleCancelPromoteAdmin}
        />
      )}
    </div>
  );
};

export default WebinarAdminUserListTab;
