import React, { useEffect, useState } from "react";
import "./PopularCourses.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";
import ModalPlayer from "../../pages/Courses/CoursePreview/ModalPlayer";
import { useNavigate } from "react-router-dom";
import StarRating from "../../pages/Courses/CoursePreview/StarRating";
import { useModalContext } from "../WebinarAdminCreateWebinarTab/ModalContext";

const PopularCourses = ({ searchQuery, courses }) => {
  //   const [data, setData] = useState([]);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [videoSource, setVideoSource] = useState("");
  const navigate = useNavigate();

  const [coursetransactions, setCoursetransactions] = useState([]);
  const [userBoughtCourses, setUserBoughtCourses] = useState([]);

  const userId = localStorage.getItem("userId");
  const isAdmin = localStorage.getItem("loggedInUsername") === "admin";

  const {
    courseTransactionDetails,
    // userId,
  } = useModalContext();

  const handleToggleVideo = (source) => {
    setVideoSource(source);
    setVideoModalOpen(!videoModalOpen);
    document.body.classList.toggle("modal-open");
  };
  function stripHtmlTags(html, maxLength) {
    let doc = new DOMParser().parseFromString(html, "text/html");
    let plainText = doc.body.innerText;
    return plainText.length > maxLength
      ? plainText.slice(0, maxLength) + "..."
      : plainText;
  }

  useEffect(() => {
    const fetchTransactionDetails = async () => {
      if (userId) {
        try {
          const transactionDetails = await courseTransactionDetails(userId);
          console.log("course TransactionData", transactionDetails);
          setCoursetransactions(transactionDetails);
        } catch (error) {
          console.error("Error fetching transaction details:", error);
        }
      }
    };

    fetchTransactionDetails();
  }, [userId]);

  useEffect(() => {
    const boughtCourses = coursetransactions
      ?.filter((transaction) => transaction?.status.toLowerCase() === "success")
      ?.map((transaction) => transaction?.productID);
    console.log("bought Courses", boughtCourses);
    setUserBoughtCourses(boughtCourses);
  }, [coursetransactions]);

  return (
    <div className="ir-pop-courses-main-container">
      <div className="ir-ws-banner-padding-tb ir-ws-padding-lr-132 ir-pop-courses-inner-container">
        <h3 className="ir-ws-text-center ir-ws-pop-courses-title ir-ws-heading-default-color text-[30px] font-[500] ir-ws-webinar-page-webinar-list-title">
          Most Popular Courses
        </h3>
        <div className="ir-pop-courses-main-container">
          <Swiper
            // spaceBetween={30}
            // slidesPerView={4}
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              1400: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {courses?.slice(0, 8).map((course) => (
              <SwiperSlide key={course._id}>
                <div className="ir-courses-container-main-container">
                  <div className="ir-courses-container-inner-container">
                    <div
                      className="ir-courses-container-thumb-container"
                      onClick={() => {
                        if (course.formData.videoPreview !== "") {
                          handleToggleVideo(course.formData.videoPreview);
                        } else {
                          return;
                        }
                      }}
                    >
                      <div className="ir-courses-container-thumb-img-container">
                        <img
                          className="ir-courses-container-thumb-img"
                          src={course.formData.imagePreview}
                          alt="thumbnail"
                        />
                        <div className="ir-courses-container-play-container">
                          <i className="ir-cours-list-grid-play-btn ir-pop-course-play-btn bi bi-play-circle-fill"></i>
                        </div>
                        <div className="ir-ws-course-homepage-rating-container">
                          <StarRating
                            iconWidth={25}
                            iconHeight={25}
                            iconGap={5}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="ir-courses-container-content-container">
                      <div className="ir-courses-container-content-inner-container">
                        <div className="ir-courses-container-title-container">
                          {course.formData.title}
                        </div>

                        <div
                          className="ir-courses-content-container-para"
                          title={stripHtmlTags(course.formData.description)}
                        >
                          {stripHtmlTags(course.formData.description, 120)}
                        </div>
                        <div className="ir-courses-price-bottom-container">
                          <div className="ir-courses-container-price">
                            {userBoughtCourses?.includes(course._id) ||
                            isAdmin ? // <div
                            //   className="ir-courses-container-readmore"
                            //   onClick={() => navigate(`/course/${course._id}`)}
                            //   style={{
                            //     cursor: "pointer"
                            //   }}
                            // >
                            //   View
                            // </div>
                            null : (
                              <div className="">
                                $ {course.formData.coursePrice}
                              </div>
                            )}
                          </div>
                          <a
                            href={`/course/${course._id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ir-courses-container-readmore"
                          >
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="ir-pop-static-container">
          <div className="ir-pop-static-inner-container">
            <p className="ir-pop-static-content-title">
              Access <span className="ir-ws-app-color">IR4U's</span>{" "}
              interventional radiology courses effortlessly from your laptop or
              mobile. Learn on-the-go with seamless platform integration
            </p>
            <div className="ir-pop-static-content-container">
              <div className="ir-ws-flex ir-pop-static-inner-content-container">
                <div className="ir-ws-flex ir-ws-pop-static div-container">
                  <img
                    src={require("../../assets/images/notebook-computer.png")}
                  />
                  <p>
                    Enjoy seamless web compatibility with adaptive streaming
                    videos on IR4U. Experience uninterrupted learning across
                    devices with optimized video playback
                  </p>
                </div>
                <div className="ir-ws-flex ir-ws-pop-static div-container">
                  <img src={require("../../assets/images/smartphone.png")} />
                  <p>
                    Enjoy seamless web compatibility with adaptive streaming
                    videos on IR4U. Experience uninterrupted learning across
                    devices with optimized video playback
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {videoModalOpen && (
        <ModalPlayer videoSource={videoSource} onClick={handleToggleVideo} />
      )}
    </div>
  );
};

export default PopularCourses;
