// import axios from "./axios";
import axios from "axios";
import Swal from "sweetalert2";

const BASE_URL = "https://backend.ir4u.info/api/v1";

/**
 * Make a user a secondary admin.
 * @param {string} userId - The ID of the user to be assigned the secondary admin role.
 */
export const addSecondaryAdmin = async (userId) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/admin/add-role/${userId}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      }
    );

    Swal.fire({
      title: "Success!",
      text: "User has been made a secondary admin",
      icon: "success",
    });

    return response.data;
  } catch (error) {
    Swal.fire({
      title: "Error!",
      text: error.response?.data?.message || "Something went wrong",
      icon: "error",
    });
    throw error;
  }
};

/**
 * Remove secondary admin role from a user.
 * @param {string} userId - The ID of the user to remove from the secondary admin role.
 */
export const removeSecondaryAdmin = async (userId) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/admin/remove-role/${userId}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("jwtTokenApi1"),
        },
      }
    );

    Swal.fire({
      title: "Success!",
      text: "User is no longer a secondary admin",
      icon: "success",
    });

    return response.data;
  } catch (error) {
    Swal.fire({
      title: "Error!",
      text: error.response?.data?.message || "Something went wrong",
      icon: "error",
    });
    throw error;
  }
};
