import React from "react";
import { io } from "socket.io-client";
import { BaseURL as ENDPOINT } from "../../../api/axios";
import ChatAdminRoomContent from "../../../pages/ChatAdminRoom/ChatAdminRoomContent";
import { PaymentProvider } from "../../../context/PaymentContext";
import ChatAdminRoomTab from "../../../pages/ChatAdminRoom/ChatAdminRoomTab";
const ChatPanel = () => {
  const socketio = io(ENDPOINT, {
    autoConnect: false,
    reconnectionDelayMax: 10000,
    auth: {
      token: "Bearer " + localStorage.getItem("jwtTokenApi2"),
    },
    query: {
      "my-key": "my-value",
    },
  });
  return (
    <div className="h-[100%]">
      <ChatAdminRoomContent />
      <PaymentProvider>
        <ChatAdminRoomTab socket={socketio} />
      </PaymentProvider>
    </div>
  );
};

export default ChatPanel;
