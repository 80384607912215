import React, { useState } from "react";
import CourseAdminContent from "../../../pages/Courses/CourseAdminContent/CourseAdminContentTab";
import AdminCreateCourseContent from "../../../pages/Courses/CourseAdminContent/AdminCreateCourseContent";

const CoursesPanel = () => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className="h-[100%]">
      <CourseAdminContent toggle={toggle} setToggle={setToggle} />
      <AdminCreateCourseContent toggle={toggle} setToggle={setToggle} />
    </div>
  );
};

export default CoursesPanel;
