import React, { useState } from "react";
import { useModalContext } from "../../WebinarAdminCreateWebinarTab/ModalContext";

const AdminDataComponent = ({ type, title, status, data, dataInfo }) => {
  const { userList, webinarData } = useModalContext();
  const [activeDropdown, setActiveDropdown] = useState(null);
  function getFirstLetter(name) {
    if (typeof name !== "string" || name.length === 0) {
      return "Invalid input";
    }
    return name.charAt(0);
  }
  return (
    <div className="w-[80%] ph-box-shadow h-[100%]">
      <div className="bg-[#FFFFFF]  rounded-[8px] h-[100%] text-[#1C1D26]">
        <div className="xl:p-[12px] 3xl:p-[20px] h-[100%] flex flex-col">
          <div className="flex items-center justify-between">
            <div>
              <p className="xl:text-[14px] text-[#0A0815] font-[700] 3xl:text-[16px]">
                {type}
              </p>
            </div>
            {/* <div className="text-[#646464] text-[24px] cursor-pointer ">
              <i className="bi bi-three-dots"></i>
            </div> */}
          </div>
          <div className="pt-[16px] xl:pt-[12px]">
            <div className="w-[100%] rounded-[8px] xl:text-[12px] 3xl:text-[14px] font-[500] p-[4px] text-[#5D5B73] bg-[#F6F6F6] flex items-center justify-between">
              <div className="w-[33%] pl-[4px]  ">
                <p>{title}</p>
              </div>
              <div className="w-[33%] text-center">
                <p>{data}</p>
              </div>
              <div className="w-[33%] text-center pr-[4px]">
                <p>{status}</p>
              </div>
              <div className="w-[33%] text-center pr-[4px]">
                <p>{dataInfo}</p>
              </div>
            </div>
          </div>

          {type === "Product List" && (
            <div className="ph-user-product-cont">
              {webinarData?.map((item) => (
                <div
                  className="pt-[8px] xl:pt-[4px] 3xl:pt-[8px]"
                  key={item.id}
                >
                  <div className="w-[100%] p-[4px] text-[#5D5B73] xl:text-[12px] 3xl:text-[14px]  flex items-center justify-between">
                    <div className="w-[33%] pl-[4px]">
                      <div className="flex items-center gap-[8px]">
                        <div className="w-[40px] h-[40px] xl:w-[28px] xl:h-[28px] 3xl:w-[34px] 3xl:h-[34px]  bg-[#D9D9D9] rounded-[8px]"></div>
                        <p className="text-[#0A0815] ">{item.title}</p>
                      </div>
                    </div>
                    <div className="w-[33%] text-center">
                      <p>Webinar</p>
                    </div>
                    <div className="w-[33%] text-center">
                      <p>{item.status}</p>
                    </div>
                    <div className="w-[33%] text-center">
                      <p>0</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {type === "Users" && (
            <div className="ph-user-product-cont">
              {userList.map((item, index) => (
                <div
                  className="pt-[8px] xl:pt-[4px] 3xl:pt-[8px]"
                  key={item.index}
                >
                  <div className="w-[100%] p-[4px] text-[#5D5B73] xl:text-[12px] 3xl:text-[14px]  flex items-center justify-between">
                    <div className="w-[33%] pl-[4px]">
                      <div className="flex items-center gap-[8px]">
                        <div className="flex items-center justify-center w-[40px] h-[40px] xl:w-[28px] xl:h-[28px] 3xl:w-[34px] 3xl:h-[34px]    bg-[#D9D9D9] rounded-[8px]">
                          {item?.userImage ? (
                            <img
                              src={item?.userImage}
                              alt="User"
                              className="w-[100%] h-[100%] object-cover rounded-[8px]"
                            />
                          ) : (
                            <p className="uppercase font-[600]">
                              {getFirstLetter(item?.firstname)}
                            </p>
                          )}
                        </div>
                        <p className="text-[#0A0815]  ">{item?.username}</p>
                      </div>
                    </div>
                    <div className="w-[33%] text-center">
                      <p>{item?.firstname}</p>
                    </div>
                    <div
                      className="w-[33%] text-center relative flex items-center cursor-pointer justify-center gap-[4px]"
                      onClick={() =>
                        setActiveDropdown(
                          activeDropdown === index ? null : index
                        )
                      }
                    >
                      <p>{item?.role[0]}</p>
                      <i
                        className={`bi bi-chevron-${
                          activeDropdown === index ? "up" : "down"
                        } text-[#0A0815] xl:text-[12px] mt-[2px] 3xl:text-[14px]`}
                      ></i>
                      {activeDropdown === index && (
                        <div className="absolute right-[25px] xl:right-[20px] 3xl:right-[25px] bottom-[-5px]   bg-[#F2F6FE] border rounded ">
                          <ul className="p-[8px] xl:p-[6px] 3xl:p-[8px]">
                            <li
                              className=" text-[#1C1D26] cursor-pointer  xl:text-[10px] 3xl:text-[12px]"
                              // onClick={handleLogout}
                            >
                              Promote
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="w-[33%] text-center">
                      <p>{item?.email}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AdminDataComponent;
