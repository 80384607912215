// // import { initializeApp } from "firebase/app";
// // import { getMessaging, getToken, onMessage } from "firebase/messaging";

// // // TODO: Add SDKs for Firebase products that you want to use
// // // https://firebase.google.com/docs/web/setup#available-libraries

// // // Your web app's Firebase configuration
// // // For Firebase JS SDK v7.20.0 and later, measurementId is optional

// // const firebaseConfig = {
// //   apiKey: "AIzaSyAbISinH8Zc5pS-AM8xAHlQNyeMbLTVva4",
// //   authDomain: "ir4u-notifications.firebaseapp.com",
// //   projectId: "ir4u-notifications",
// //   storageBucket: "ir4u-notifications.firebasestorage.app",
// //   messagingSenderId: "94798116312",
// //   appId: "1:94798116312:web:2a3abda6e346ed2127aead",
// //   measurementId: "G-T7KJLBW815",
// //   vapidKey:
// //     "BBxTqZVSM40Q4BnOF4wKxBPtY1tNuQ1EMuBFWiryB3Egpc_-GI74N9rw5yns2tAIxl184gDv8RnaWWSzLga5gYU",
// // };

// // // Initialize Firebase
// // const app = initializeApp(firebaseConfig);
// // const messaging = getMessaging(app);

// // // Request Permission & Get Token
// // export const requestNotificationPermission = async () => {
// //   try {
// //     const permission = await Notification.requestPermission();
// //     if (permission === "granted") {
// //       const token = await getToken(messaging, {
// //         vapidKey: firebaseConfig.vapidKey,
// //       });
// //       return token;
// //     } else {
// //       console.log("Notification permission denied.");
// //       return null;
// //     }
// //   } catch (error) {
// //     console.error("Error getting token: ", error);
// //     return null;
// //   }
// // };

// // // Listen for foreground messages
// // export const onMessageListener = () =>
// //   new Promise((resolve) => {
// //     onMessage(messaging, (payload) => {
// //       console.log("Message received: ", payload);
// //       resolve(payload);
// //     });
// //   });

// // export default messaging;

// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyAbISinH8Zc5pS-AM8xAHlQNyeMbLTVva4",
//   authDomain: "ir4u-notifications.firebaseapp.com",
//   projectId: "ir4u-notifications",
//   storageBucket: "ir4u-notifications.firebasestorage.app",
//   messagingSenderId: "94798116312",
//   appId: "1:94798116312:web:2a3abda6e346ed2127aead",
//   measurementId: "G-T7KJLBW815",
//   vapidKey:
//     "BBxTqZVSM40Q4BnOF4wKxBPtY1tNuQ1EMuBFWiryB3Egpc_-GI74N9rw5yns2tAIxl184gDv8RnaWWSzLga5gYU",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

// // Request Notification Permission & Get FCM Token
// export const requestNotificationPermission = async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     if (permission === "granted") {
//       const token = await getToken(messaging, {
//         vapidKey: firebaseConfig.vapidKey,
//       });
//       return token;
//     } else {
//       console.log("Notification permission denied.");
//       return null;
//     }
//   } catch (error) {
//     console.error("Error getting FCM token: ", error);
//     return null;
//   }
// };

// // Listen for foreground messages
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("Message received: ", payload);
//       resolve(payload);
//     });
//   });

// export default messaging;

// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// // Firebase config
// const firebaseConfig = {
//   apiKey: "AIzaSyAbISinH8Zc5Z-AM8xAHlQNyeMbLTVva4",
//   authDomain: "ir4u-notifications.firebaseapp.com",
//   projectId: "ir4u-notifications",
//   storageBucket: "ir4u-notifications.firebasestorage.app",
//   messagingSenderId: "94798116312",
//   appId: "1:94798116312:web:2a3abda6e346ed2127aead",
//   measurementId: "G-T7KJLBW815",
//   vapidKey:
//     "BBxTqZVSM40Q4BnOF4wKxBPtY1tNuQ1EMuBFWiryB3Egpc_-GI74N9rw5yns2tAIxl184gDv8RnaWWSzLga5gYU",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

// // Register service worker
// navigator.serviceWorker
//   .register("/firebase-messaging-sw.js")
//   .then((registration) => {
//     console.log("Service Worker registered with scope:", registration.scope);
//   })
//   .catch((error) => {
//     console.error("Service Worker registration failed:", error);
//   });

// // Request Notification Permission & Get Token
// export const requestNotificationPermission = async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     if (permission === "granted") {
//       const token = await getToken(messaging, {
//         vapidKey: firebaseConfig.vapidKey,
//         serviceWorkerRegistration: await navigator.serviceWorker.ready,
//       });
//       return token;
//     } else {
//       console.log("Notification permission denied.");
//       return null;
//     }
//   } catch (error) {
//     console.error("Error getting FCM token: ", error);
//     return null;
//   }
// };

// // Foreground message listener
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("Message received: ", payload);
//       resolve(payload);
//     });
//   });

// export default messaging;

// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// // Firebase config
// const firebaseConfig = {
//   apiKey: "AIzaSyAbISinH8Zc5Z-AM8xAHlQNyeMbLTVva4",
//   authDomain: "ir4u-notifications.firebaseapp.com",
//   projectId: "ir4u-notifications",
//   storageBucket: "ir4u-notifications.firebasestorage.app",
//   messagingSenderId: "94798116312",
//   appId: "1:94798116312:web:2a3abda6e346ed2127aead",
//   measurementId: "G-T7KJLBW815",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

// // Register service worker
// navigator.serviceWorker
//   .register("/firebase-messaging-sw.js")
//   .then((registration) => {
//     console.log("Service Worker registered with scope:", registration.scope);
//   })
//   .catch((error) => {
//     console.error("Service Worker registration failed:", error);
//   });

// // Request Notification Permission & Get Token
// export const requestNotificationPermission = async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     if (permission === "granted") {
//       const registration = await navigator.serviceWorker.ready;
//       const token = await getToken(messaging, {
//         vapidKey:
//           "BBxTqZVSM40Q4BnOF4wKxBPtY1tNuQ1EMuBFWiryB3Egpc_-GI74N9rw5yns2tAIxl184gDv8RnaWWSzLga5gYU",
//         serviceWorkerRegistration: registration,
//       });

//       console.log("FCM Token:", token);
//       return token;
//     } else {
//       console.log("Notification permission denied.");
//       return null;
//     }
//   } catch (error) {
//     console.error("Error getting FCM token: ", error);
//     return null;
//   }
// };

// // Foreground message listener
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("Message received: ", payload);
//       resolve(payload);
//     });
//   });

// export default messaging;

// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// // Firebase config
// const firebaseConfig = {
//   // apiKey: "AIzaSyAbISinH8Zc5Z-AM8xAHlQNyeMbLTVva4",
//   apiKey: "AIzaSyAbISinH8Zc5pS-AM8xAHlQNyeMbLTVva4",
//   authDomain: "ir4u-notifications.firebaseapp.com",
//   projectId: "ir4u-notifications",
//   storageBucket: "ir4u-notifications.firebasestorage.app",
//   messagingSenderId: "94798116312",
//   appId: "1:94798116312:web:2a3abda6e346ed2127aead",
//   measurementId: "G-T7KJLBW815",
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

// // Register Service Worker
// navigator.serviceWorker
//   .register("/firebase-messaging-sw.js")
//   .then((registration) => {
//     console.log("Service Worker registered with scope:", registration.scope);
//   })
//   .catch((error) => {
//     console.error("Service Worker registration failed:", error);
//   });

// export const requestNotificationPermission = async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     if (permission === "granted") {
//       console.log("Notification permission granted.");

//       // Wait for service worker to be ready
//       const registration = await navigator.serviceWorker.ready;

//       const token = await getToken(messaging, {
//         vapidKey:
//           "BBxTqZVSM40Q4BnOF4wKxBPtY1tNuQ1EMuBFWiryB3Egpc_-GI74N9rw5yns2tAIxl184gDv8RnaWWSzLga5gYU",
//         serviceWorkerRegistration: registration,
//       });

//       console.log("FCM Token:", token);
//       return token;
//     } else {
//       console.log("Notification permission denied.");
//       return null;
//     }
//   } catch (error) {
//     console.error("Error getting FCM token: ", error);
//     return null;
//   }
// };

// // Foreground message listener
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       console.log("Message received: ", payload);
//       resolve(payload);
//     });
//   });

// export default messaging;

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAbISinH8Zc5pS-AM8xAHlQNyeMbLTVva4",
  authDomain: "ir4u-notifications.firebaseapp.com",
  projectId: "ir4u-notifications",
  storageBucket: "ir4u-notifications.appspot.com",
  messagingSenderId: "94798116312",
  appId: "1:94798116312:web:2a3abda6e346ed2127aead",
  measurementId: "G-T7KJLBW815",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

/**
 * Request notification permission and get FCM token
 */
export const requestNotificationPermission = async () => {
  try {
    console.log("Requesting notification permission...");
    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      console.log("Notification permission denied.");
      return null;
    }

    console.log("Notification permission granted.");

    // Ensure Service Worker is ready
    const registration = await navigator.serviceWorker.ready;
    console.log("Service Worker ready, fetching token...");

    const vapidKey =
      "BBxTqZVSM40Q4BnOF4wKxBPtY1tNuQ1EMuBFWiryB3Egpc_-GI74N9rw5yns2tAIxl184gDv8RnaWWSzLga5gYU";
    const token = await getToken(messaging, {
      vapidKey,
      serviceWorkerRegistration: registration,
    });

    if (token) {
      console.log("FCM Token received:", token);
      return token;
    } else {
      console.error(
        "Failed to get FCM Token. Check VAPID key, service worker, or browser settings."
      );
      return null;
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
    return null;
  }
};

// Listen for foreground messages
onMessage(messaging, (payload) => {
  console.log("Received foreground message:", payload);
});
