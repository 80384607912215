import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addFormData } from "../Store/CourseSlice";
import { useModalContext } from "../../../components/WebinarAdminCreateWebinarTab/ModalContext";

const AdminCourseDraft = () => {
  const {
    setActiveTabCourse,
    handleCreatecourspage,
    draftCourseUpdateId,
    setDraftCourseUpdateId,
  } = useModalContext();
  const [drafts, setDrafts] = useState([]);
  const [draftFormdataOfCurrentCourse, setDraftFormdataOfCurrentCourse] =
    useState(null);
  const dispatch = useDispatch();

  const fetchDrafts = async () => {
    const url = "https://courses.ir4u.info/api/course/drafts/all";
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("draft data:", data);
      setDrafts(data);
    } catch (error) {
      console.error("Failed to fetch drafts:", error);
    }
  };

  useEffect(() => {
    fetchDrafts();
  }, []);

  const deleteDraftHandler = async (id) => {
    const url = `https://courses.ir4u.info/api/course/draft/${id}`;
    alert("Deleting draft");
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Draft deleted successfully:", data);

      setDrafts(drafts.filter((draft) => draft._id !== id));
    } catch (error) {
      console.error("Failed to delete draft:", error);
    }
  };

  const handleEditDraftCourse = async (draftId) => {
    setDraftCourseUpdateId(draftId);
    try {
      setActiveTabCourse(1);
      handleCreatecourspage();

      const url = `https://courses.ir4u.info/api/course/draft/${draftId}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const formData = data.formData;
      const updatedFormData = { ...formData };

      setDraftFormdataOfCurrentCourse(updatedFormData);
      dispatch(addFormData(updatedFormData));
    } catch (error) {
      console.error("Error fetching draft data:", error);
    }
  };

  // Update the useEffect to trigger form update when drafts change
  // useEffect(() => {
  //   if (draftFormdataOfCurrentCourse) {
  //     dispatch(addFormData(draftFormdataOfCurrentCourse));
  //   }
  // }, [draftFormdataOfCurrentCourse, dispatch]);

  return (
    <div>
      {drafts?.map((draft, index) => (
        <div className="ir-ws-webinar-list-tab" key={draft._id}>
          <div className="ir-ws-admin-webinar-content">
            <div className="ir-ws-admin-webinar-title-container">
              <img
                src={draft.formData.imagePreview}
                className="ir-ws-course-list-image"
                alt="course"
              />
              <p className="ir-ws-webinar-title-text">{draft.formData.title}</p>
            </div>
            <div className="ir-ws-admin-list-button-container">
              <button
                className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                onClick={() => handleEditDraftCourse(draft._id)}
              >
                Edit
              </button>
              <button
                className="ir-ws-webinar-cancel-button ir-ws-webinar-actions-button"
                onClick={() => deleteDraftHandler(draft._id)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default AdminCourseDraft;
