import React from "react";
import HomeTeamContainer from "./../HomeTeamContainer/HomeTeamContainer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeExpertsContainer.scss";

const HomeExpertsContainer = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="ir-ws-padding-tb-100 ir-ws-padding-lr-132 ir-ws-hme-exprt-main-container">
      <h2 className="ir-ws-text-center ir-ws-hme-webinar-title font-[500] text-[32px]">
        What Experts say about <span className="ir-ws-app-color ">IR4U</span>
      </h2>
      <div className="ir-ws-hme-exprt-inner-container">
        <Slider {...settings}>
          <div className="ir-ws-hme-prvw-card-container">
            <div className="ir-ws-position-relative ir-ws-card-main-container">
              <div className="ir-ws-position-absolute ir-ws-border-50 ir-ws-text-center ir-ws-card-header ir-ws-hme-exprt-img-card">
                <img
                  className="ir-ws-prvw-card-thumbnail-container ir-ws-border-50"
                  src={require("../../assets/images/expert-4.1.webp")}
                  alt="Courses thumbnail"
                />
              </div>
              <div className="ir-ws-card-body ir-ws-hme-exprt-card-body-content-container">
                <h4 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-hme-exprt-name">
                  Dr K Karuppasamy MD,
                </h4>
                <p className="ir-ws-text-center ir-ws-heading-default-color ir-margin-0 ir-ws-hme-exprt-designation">
                  Interventional radiologist, <br />
                  Cleveland Clinic
                </p>
                <div className="ir-ws-position-relative ir-ws-hme-exprt-card-content-container">
                  <p className="ir-default-color">
                    IR4U has been painstakingly built by Dr. Ram Gurajala to
                    benefit all of us who perform pinhole surgeries. Dr. Ram is
                    extremely passionate about equitable access to education and
                    has commendable expertise in providing hands-on training.
                    I've been practicing medicine for nearly 15 years, and IR4U
                    remains a useful tool that I frequently visit to see, learn,
                    and improve my knowledge.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ir-ws-hme-prvw-card-container">
            <div className="ir-ws-position-relative ir-ws-card-main-container">
              <div className="ir-ws-position-absolute ir-ws-border-50 ir-ws-text-center ir-ws-card-header ir-ws-hme-exprt-img-card">
                <img
                  className="ir-ws-prvw-card-thumbnail-container ir-ws-border-50"
                  src={require("../../assets/images/expert-2.1.webp")}
                  alt="Courses thumbnail"
                />
              </div>
              <div className="ir-ws-card-body ir-ws-hme-exprt-card-body-content-container">
                <h4 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-hme-exprt-name">
                  Dr Karthik Damodharan, FRCR
                </h4>
                <p className="ir-ws-text-center ir-ws-heading-default-color ir-margin-0 ir-ws-hme-exprt-designation">
                  Interventional Radiologist, <br />
                  MIOT International, Chennai, India
                </p>
                <div className="ir-ws-position-relative ir-ws-hme-exprt-card-content-container">
                  <p className="ir-default-color">
                    IR4U is a highly interactive educational forum on practical
                    topics in IR which is useful for interventionalists across
                    all levels of expertise right from aspiring IR's to
                    consultants. Through chat rooms, case discussions, courses
                    IR4U has everything to offer for Interventional radiology.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ir-ws-hme-prvw-card-container">
            <div className="ir-ws-position-relative ir-ws-card-main-container">
              <div className="ir-ws-position-absolute ir-ws-border-50 ir-ws-text-center ir-ws-card-header ir-ws-hme-exprt-img-card">
                <img
                  className="ir-ws-prvw-card-thumbnail-container ir-ws-border-50"
                  src={require("../../assets/images/expert-1.1.webp")}
                  alt="Courses thumbnail"
                />
              </div>
              <div className="ir-ws-card-body ir-ws-hme-exprt-card-body-content-container">
                <h4 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-hme-exprt-name">
                  Dr Y Reddi Prasad, FRCR
                </h4>
                <p className="ir-ws-text-center ir-ws-heading-default-color ir-margin-0 ir-ws-hme-exprt-designation">
                  Interventional Radiologist, <br /> Manipal Hospitals,
                  Bangalore, India
                </p>
                <div className="ir-ws-position-relative ir-ws-hme-exprt-card-content-container">
                  <p className="ir-default-color">
                    These are the times when a lot of education is being
                    delivered online by many institutions across the world. I am
                    delighted that Dr Ram Gurajala has put in a lot of effort
                    and built the IR4U website. This, I am sure, is going to be
                    very useful for every IR, whether they wish to gain new
                    knowledge, refresh about a procedure, or discuss a clinical
                    scenario.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ir-ws-hme-prvw-card-container">
            <div className="ir-ws-position-relative ir-ws-card-main-container">
              <div className="ir-ws-position-absolute ir-ws-border-50 ir-ws-text-center ir-ws-card-header ir-ws-hme-exprt-img-card">
                <img
                  className="ir-ws-prvw-card-thumbnail-container ir-ws-border-50"
                  src={require("../../assets/images/expert-3.1.webp")}
                  alt="Courses thumbnail"
                />
              </div>
              <div className="ir-ws-card-body ir-ws-hme-exprt-card-body-content-container">
                <h4 className="ir-ws-text-center ir-ws-heading-default-color ir-ws-hme-exprt-name">
                  Dr P RadhaKrishna, FRCR
                </h4>
                <p className="ir-ws-text-center ir-ws-heading-default-color ir-margin-0 ir-ws-hme-exprt-designation">
                  Interventional Radiologist, <br />
                  The Royal Prince Alfred Hospital, Sydney, Australia
                </p>
                <div className="ir-ws-position-relative ir-ws-hme-exprt-card-content-container">
                  <p className="ir-default-color">
                    This is a great initiative providing valuable information to
                    IR trainees/Practitioner, referring clinicians and most
                    importantly, patients regarding Interventional radiology in
                    one easily accessible location.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <HomeTeamContainer />
    </div>
  );
};

export default HomeExpertsContainer;
